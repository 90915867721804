<template>
    <div class="spk-info">
        <el-popover
            trigger="click"
            @after-enter="showUpdateSeparationDialog(section)"
            ref="popover"
            popper-class="separation-el-popover"
            @show="beforeShowUpdateSeparationDialog"
        >
            <div
                v-show="section.spkName && isSeparation"
                class="spk-name"
                slot="reference"
                contenteditable="false"
            >
                {{ section.spkName ? section.spkName : '' }}
            </div>
            <el-row>
                <el-input
                    :value="afterSeparationName"
                    placeholder="请输入名字"
                    maxlength="10"
                    @input="rewriteSpkName"
                ></el-input>
            </el-row>
            <el-row style="margin-top:10px">
                <span>Suggestion</span>
            </el-row>
            <div class="speaker-list">
                <el-button
                    v-for="(item, i) in speakerList"
                    :key="i"
                    class="spk_btn"
                    @click="chooseAfterSeparationName(item.spkName)"
                    round
                >
                    <div
                        class="circleIcon"
                        :style="`background:${randomColor(i)}`"
                    >
                        {{ item.spkName.substr(item.spkName.length - 2) }}
                    </div>
                    {{ item.spkName }}
                </el-button>
            </div>

            <el-row style="margin-top:10px">
                <el-checkbox v-model="alternateStatus"
                    >Modify in batch"{{ afterSeparationName }}"</el-checkbox
                >
            </el-row>
            <el-row style="margin-top:10px;float:right">
                <el-button
                    type="primary"
                    @click="updateSeparationLocal(section)"
                    round
                    >Modify</el-button
                >
            </el-row>
        </el-popover>
        <div v-show="isStampEnable" class="spk-time" contenteditable="false">
            {{ section.startTime }}
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { generateRandomColor } from '../../utils/CommonUtils';
export default {
    name: 'SpkInfo',
    props: [
        'sectionProp',
        'showData',
        // TextEditor 方法
        'showUpdateSeparationDialog',
        'beforeShowUpdateSeparationDialog',
        'chooseAfterSeparationName',
        'updateSpeakerList'
    ],
    data() {
        return {
            alternateStatus: false,
            section: this.sectionProp
        };
    },
    mounted() {},
    methods: {
        randomColor(i) {
            return generateRandomColor(i);
        },
        rewriteSpkName(spkName) {
            this.$store.commit('voiceDetail/setAfterSeparationName', spkName);
        },
        updateSeparationLocal() {
            if (
                this.afterSeparationName === '' ||
                this.afterSeparationName === this.beforeSeparationName
            ) {
                // 这里存在一个问题，通过全局方法生成的spk-info并不会被Vue感知到，自然内部的popover也并不会被外部组件感知到
                // 同时新生成的section和原section使用的是同一个id，因此绑定在上面的方法对新popover并不生效
                // 因此只能折中在组件内部再调用该方法并加以修改
                this.$refs.popover.doClose();
                return;
            }
            const flag = new RegExp('^[\u4E00-\u9FA5A-Za-z0-9]+$');
            // 判断 even 是否包含特殊字符
            if (!flag.test(this.afterSeparationName)) {
                this.$message.error('Enter Chinese characters, English and numbers');
                return;
            }
            if (this.alternateStatus) {
                // showData是父组件传过来的单向数据流，但是因为其是引用值，所以反向传值不会被Vue报异常
                // 但是最好的方式是使用vuex进行修改，下面的方式并不推荐
                for (let i = 0; i < this.showData.length; i++) {
                    if (
                        this.showData[i].spkName === this.beforeSeparationName
                    ) {
                        this.showData[i].spkName = this.afterSeparationName;
                    }
                }
            }
            // 新的spk-info不在Vm中,需要单独处理
            this.section = {
                ...this.section,
                spkName: this.afterSeparationName
            };
            // 新生成的section在修改了spkName后，外部section上的section属性值并没有改变
            // 因为父组件的该方法会修改showData，进而导致动态绑定的section重新渲染
            // 而自动生成的section缺少了动态绑定，需要在修改说话人时动态修改section的spkName
            // 首先获取外部section
            const sectionNode = this.$refs.popover.$parent.$el.parentNode;
            // 修改外部section的spkName
            sectionNode.setAttribute('spkName', this.afterSeparationName);
            this.$store.commit('voiceDetail/setAfterSeparationName', '');
            this.$refs.popover.doClose();
            // this.updateSpeakerList(); 对于生成的section没有作用，主要用于showData
            // 在生成的section中主要指的是批量修改时可能涉及到的原生section
            this.updateSpeakerList();
        }
    },
    computed: {
        ...mapState('voiceDetail', {
            afterSeparationName: state => state.afterSeparationName,
            speakerList: state => state.speakerList,
            beforeSeparationNameSize: state => state.beforeSeparationNameSize,
            beforeSeparationName: state => state.beforeSeparationName,
            exchangeSpkName: state => state.exchangeSpkName,
            isSeparation: state => state.isSeparation,
            isStampEnable: state => state.isStampEnable
        })
    },
    watch: {
        alternateStatus(newVal) {
            this.$store.commit('voiceDetail/setAlternateStatus', newVal);
        },
        exchangeSpkName(newVal) {
            // 判断和批量修改同名后对section的spkName进行修改
            if (newVal.proName === this.section.spkName) {
                this.section = { ...this.section, spkName: newVal.newName };
            }
        }
    }
};
</script>

<style scoped></style>
