<template>
    <!-- 兑换 -->
    <el-dialog
        class="redeem-dialog"
        v-if="redeemVis"
        :visible.sync="redeemVis"
        :title="$t('兑换确认')"
        :close-on-click-modal="false"
        :before-close="beforeClose"
    >
        <redeem-item
            ref="redeemItem"
            :goods="goods"
            :neededCoin="neededCoin"
            :showRedeem="false"
        ></redeem-item>
        <div
            :class="['redeem-btn', !enoughForOne && 'disabled']"
            @click="handleRedeem"
        >
            {{ enoughForOne ? $t('立即兑换') : $t('悦豆不足') }}
        </div>
    </el-dialog>
</template>

<script>
import RedeemItem from './RedeemItem.vue';
import { purchaseGoods } from '@/api/signIn-api';
import createRewardBox from '@/extend/rewardBox';
import { mapState } from 'vuex';

export default {
    props: {
        redeemVis: {
            type: Boolean,
            default: false
        },
        goods: {
            type: Object,
            default: {
                discount: 0,
                duration: 0,
                effectiveTime: 0,
                exchangeCoin: 0,
                goodsId: 1,
                goodsIntroduce: null,
                goodsName: '半小时转写时长卡',
                goodsPicPath: null,
                originalCoin: 0
            }
        }
    },
    components: {
        RedeemItem
    },
    data() {
        return {
            rewardBox: null
        };
    },
    methods: {
        async handleRedeem() {
            try {
                const { totalCoins, count } = this.$refs.redeemItem;
                const res = await purchaseGoods({
                    coinNumber: totalCoins,
                    goodsId: this.goods.goodsId,
                    exchangeAmount: count,
                });
                const {
                    exchangeAmount,
                    signRewardType,
                    signRewardNumber
                } = res.data;
                const reward = {
                    type: signRewardType,
                    num: signRewardNumber,
                    amount: exchangeAmount
                };
                this.rewardBox = createRewardBox(
                    document.querySelector('#app'),
                    reward
                );
                this.rewardBox.show();
                this.$store.dispatch('getUserAsset');
            } catch (error) {
                console.log(error);
            }

            // 埋点
            window.weblog.report({
                id: 'voiceclub_idyun_webShop_exchangeConfirm_Confirm',
                action: 'click'
            });
        },
        beforeClose() {
            this.$emit('beforeClose');
        }
    },
    computed: {
        ...mapState({
            allCoinNumber: state => state.allCoinNumber
        }),
        neededCoin() {
            const isCount = Boolean(this.goods.discount);
            return isCount ? this.goods.exchangeCoin : this.originalCoin;
        },
        enoughForOne() {
            return this.neededCoin <= this.allCoinNumber;
        }
    }
};
</script>

<style scoped lang="less">
.redeem-dialog {
    /deep/ .el-dialog {
        margin-top: 0 !important;
        top: 50%;
        transform: translateY(-50%);
        width: 26%;
        min-width: 400px;
        background-color: #f7f9fa;
        border-radius: 12px;
        background-image: url(../../assets/image/shop/reward-bg.png);
        background-repeat: no-repeat;
        background-size: contain;
        background-position-y: -20px;
        .el-dialog__header {
            padding: 20px 0;
            font-weight: 600;
            color: rgba(0, 0, 0, 0.88);
        }
        .el-dialog__body {
            display: flex;
            flex-direction: column;
            padding: 0 24px 24px;
            .redeem-btn {
                margin: 0 auto;
                margin-top: 24px;
                padding: 13px 37px;
                width: fit-content;
                background: linear-gradient(123deg, #ff914a 0%, #ff3d1b 100%);
                box-shadow: 0px 8px 17px 0px #ffc9a6;
                border-radius: 55px;
                font-weight: 500;
                color: #ffffff;
                cursor: pointer;
                letter-spacing: 1px;
            }
            .redeem-btn:hover {
                opacity: 0.8;
            }
            .redeem-btn.disabled {
                background: #cccccc;
                pointer-events: none;
                box-shadow: none;
            }
        }
    }
}
</style>
