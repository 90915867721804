<template>
    <el-dialog
        custom-class="message-dialog"
        :visible.sync="visible"
        width="960px"
        :before-close="beforeClose"
    >
        <div class="message-list">
            <div class="message-header">
                <div class="message-txt">
                    <span>
                        Message{{ unreadSize() > 0 ? `(${formatUnreadSize})` : '' }}
                    </span>
                    <el-tooltip effect="dark" content="Read all">
                        <img
                            v-if="unreadSize() > 0"
                            src="../assets/image/read-all.png"
                            @click="handleReadAll"
                        />
                    </el-tooltip>
                </div>
                <div style="padding-top: 1px; height: 30px; line-height: 30px">
                    <span
                        class="sort-text"
                        :style="{
                            color: readSign === 0 ? '#1876ff' : '#4f5967'
                        }"
                        @click="changeSign"
                        >未读</span
                    >
                </div>
            </div>
            <ul class="message-body">
                <li
                    :class="[
                        'message-box',
                        selectedMsg === msg ? 'selected' : ''
                    ]"
                    v-for="(msg, index) in msgList"
                    :key="index"
                    @click="handleRead(msg)"
                >
                    <div class="message-icon">
                        <img src="../assets/image/message-icon.png" />
                    </div>
                    <div class="voice-info">
                        <div class="message-type-time">
                            <div class="message-type">
                                <img
                                    v-if="!msg.notifyRead"
                                    src="../assets/image/dot-unread.png"
                                />
                                <span :class="[msg.notifyRead ? 'read' : '']">{{
                                    msg.senderName
                                }}</span>
                            </div>
                            <div class="message-time">
                                {{
                                    msg.sendTimeStr
                                        | messageDateFormate($i18n.locale)
                                }}
                            </div>
                        </div>
                        <div
                            :class="[
                                'message-title',
                                msg.notifyRead ? 'read' : ''
                            ]"
                        >
                            {{ msg.notifyTitle }}
                        </div>
                        <div class="message-detail-split">
                            {{ msg.notifyText }}
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <div class="message-detail">
            <div v-if="selectedMsg" class="is-reading">
                <div class="detail-header">
                    <div class="message-icon">
                        <img src="../assets/image/message-icon.png" />
                    </div>
                    <div class="detail-info">
                        <div>
                            <div class="detail-type">
                                {{ selectedMsg.senderName }}
                            </div>
                            <div class="send-to-me">发给我</div>
                        </div>
                        <div class="detail-time">
                            {{
                                selectedMsg.sendTimeStr
                                    | messageDateFormate()
                            }}
                        </div>
                    </div>
                </div>
                <div class="detail-box">
                    <div class="detail-title">
                        {{ selectedMsg.notifyTitle }}
                    </div>
                    <!-- 这里不要格式化，否则会出现前置空格 -->
                    <div class="detail-content">
                        <span>{{ selectedMsg.notifyText }}</span>
                    </div>
                </div>
            </div>
            <div v-else class="no-reading">
                <img src="../assets/image/fail-icon.png" />
            </div>
        </div>
    </el-dialog>
</template>

<script>
import { fetchMessage, readMsg, readAllMsg } from '@/api/message-api';

export default {
    name: 'MessageDialog',
    inject: ['getUnreadSize', 'unreadSize'],
    props: {
        visible: {
            type: Boolean,
            default: false,
            required: true
        }
    },
    data() {
        return {
            selectedMsg: null,
            msgList: [],
            // 0: 未读 1: 已读, -1: 全部
            readSign: -1
        };
    },
    methods: {
        changeSign() {
            if (this.readSign === -1) {
                this.readSign = 0;
            } else {
                this.readSign = -1;
            }
        },
        async handleReadAll() {
            if (this.msgList.length <= 0) return;
            try {
                this.msgList.forEach(msg => {
                    msg.notifyRead = 1;
                });
                await readAllMsg();
                this.getUnreadSize();
            } catch (error) {
                console.error(error);
            }
        },
        async handleRead(msg) {
            this.selectedMsg = msg;
            // 已读不调用接口
            if (msg.notifyRead) return;
            msg.notifyRead = 1;
            try {
                await readMsg({ notifyId: msg.notifyId });
                this.getUnreadSize();
            } catch (error) {
                console.error(error);
            }
        },
        beforeClose() {
            this.$emit('beforeClose');
            this.readSign = -1;
        },
        async getMessage() {
            try {
                const data = {
                    page: 1,
                    pageSize: 9999
                };
                if (this.readSign !== -1) data.readSign = this.readSign;
                const res = await fetchMessage(data);
                const { array: msgList } = res.data;
                this.msgList = msgList || [];
                if (this.msgList[0]) this.selectedMsg = this.msgList[0];
            } catch (error) {
                console.log(error);
            }
        }
    },
    computed: {
        formatUnreadSize() {
            return this.unreadSize() > 99 ? '99+' : this.unreadSize();
        }
    },
    watch: {
        readSign() {
            this.getMessage();
        },
        visible(newVal) {
            if (newVal) this.getMessage();
        }
    }
};
</script>

<style scoped lang="less">
/deep/ .el-dialog.message-dialog {
    height: 582px;
    border-radius: 16px;
    .el-dialog__header {
        padding: 0;
    }
    .el-dialog__body {
        display: flex;
        padding: 0;
        height: 100%;
        .message-list {
            padding: 16px;
            width: 28.3%;
            height: 100%;
            .message-header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-top: 16px;
                padding-left: 16px;
                padding-bottom: 16px;
                .message-txt {
                    font-size: 20px;
                    font-weight: 600;
                    color: #1d2129;
                    line-height: 30px;
                }
                .sort-text {
                    font-size: 16px;
                    cursor: pointer;
                }
                img {
                    margin-top: -2px;
                    width: 16px;
                    height: 16px;
                    vertical-align: middle;
                    cursor: pointer;
                }
            }
            .message-body {
                height: calc(100% - 62px);
                overflow: auto;
                list-style-type: none;
                .message-box {
                    display: flex;
                    padding: 16px;
                    text-align: left;
                    border-radius: 12px;
                    cursor: pointer;
                    .message-icon {
                        flex-shrink: 0;
                        margin-right: 12px;
                        width: 48px;
                        height: 48px;
                        border-radius: 50%;
                        overflow: hidden;
                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .voice-info {
                        min-width: calc(100% - 60px);
                        max-width: calc(100% - 60px);
                        .message-type-time {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            .message-type {
                                margin-right: 14px;
                                img {
                                    width: 14px;
                                    height: 14px;
                                }
                                span {
                                    font-size: 16px;
                                    font-weight: 600;
                                    color: #243041;
                                    line-height: 28px;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                    overflow: hidden;
                                }
                                span.read {
                                    font-weight: 400;
                                }
                            }
                            .message-time {
                                font-size: 16px;
                                color: #4f5967;
                                line-height: 20px;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                overflow: hidden;
                            }
                        }
                        .message-title {
                            margin-bottom: 4px;
                            font-size: 14px;
                            font-weight: 600;
                            color: #243041;
                            line-height: 20px;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            overflow: hidden;
                        }
                        .message-title.read {
                            font-weight: 400;
                        }
                        .message-detail-split {
                            display: flex;
                            align-items: center;
                            font-size: 14px;
                            color: #4f5967;
                            line-height: 20px;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            overflow: hidden;
                        }
                    }
                }
                .message-box:hover,
                .selected {
                    background-color: #f7f8fa;
                }
            }
            .message-body::-webkit-scrollbar {
                width: 2px;
                background-color: #e5e5e5;
            }
            .message-body::-webkit-scrollbar-thumb {
                background-color: #b7b7b7;
                border-radius: 4px;
            }
            .message-body::-webkit-scrollbar-track {
                -webkit-box-shadow: 0 0 0px #808080 inset;
            }
        }
        .message-detail {
            padding: 38px 20px;
            width: 71.8%;
            height: 100%;
            background-color: #f7f8fa;
            border-radius: 0 16px 16px 0;
            text-align: left;
            .is-reading {
                height: 100%;
            }
            .detail-header {
                display: flex;
                margin-bottom: 12px;
                text-align: left;
                .message-icon {
                    flex-shrink: 0;
                    margin-right: 12px;
                    width: 48px;
                    height: 48px;
                    border-radius: 50%;
                    overflow: hidden;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
                .detail-info {
                    display: flex;
                    justify-content: space-between;
                    align-content: center;
                    flex: 1;
                    margin-right: 20px;
                    .detail-type {
                        font-size: 20px;
                        font-weight: 600;
                        color: #1d2129;
                        line-height: 30px;
                    }
                    .send-to-me {
                        font-size: 14px;
                        color: #4f5967;
                        line-height: 20px;
                    }
                    .detail-time {
                        font-size: 14px;
                        color: #4f5967;
                        line-height: 30px;
                    }
                }
            }
            .detail-box {
                padding: 20px 24px;
                max-height: calc(100% - 64px);
                overflow-y: auto;
                background-color: #ffffff;
                border-radius: 12px;
                .detail-title {
                    margin-bottom: 12px;
                    font-size: 16px;
                    font-weight: 600;
                    color: #243041;
                    line-height: 28px;
                }
                .detail-content {
                    font-size: 16px;
                    color: #1d2129;
                    line-height: 28px;
                    white-space: pre-wrap;
                }
            }
            .detail-box::-webkit-scrollbar {
                width: 2px;
                background-color: #e5e5e5;
            }
            .detail-box::-webkit-scrollbar-thumb {
                background-color: #b7b7b7;
                border-radius: 4px;
            }
            .detail-box::-webkit-scrollbar-track {
                -webkit-box-shadow: 0 0 0px #808080 inset;
            }
            .no-reading {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                text-align: center;
                img {
                    width: 140px;
                }
            }
        }
    }
}
</style>
