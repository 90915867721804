import { getMenuList, addMenu } from '@/api/common-api';

const state = {
    menuList: []
};

const mutations = {
    getMenuList(state, data) {
        state.menuList = data;
    }
};

const actions = {
    async fetchMenuList(context, callback) {
        try {
            const res = await getMenuList();
            const menuList = res.data.map(menu => ({
                ...menu,
                menuId: String(menu.menuId)
            }));
            context.commit('getMenuList', menuList);
            callback && callback();
        } catch (error) {
            console.log(error);
        }
    },
    async addMenu(context, { menuName, callback }) {
        try {
            await addMenu({ menuName });
            context.dispatch('fetchMenuList', callback);
        } catch (error) {
            console.log(error);
        }
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
