<template>
    <div>
        <el-dialog
            class="shop-dialog"
            :visible.sync="shopVis"
            :close-on-click-modal="false"
            :before-close="beforeClose"
        >
            <div slot="title" class="shop-header">
                <img
                    src="../../assets/image/shop/shop-icon.png"
                    alt="悦豆商城"
                    style="width: 20px; vertical-align: sub;"
                />
                <span>{{ $t('商城') }}</span>
                <el-popover trigger="hover">
                    <img
                        class="tip-icon"
                        slot="reference"
                        src="../../assets/image/shop/tip-icon.png"
                        alt="提示"
                        @mouseenter="shopTipHover"
                    />
                    <div class="shop-tip">
                        <h3 style="text-align: center">{{ $t('商城说明') }}</h3>
                        <p>
                            {{ $t('用户可以在商城购买或用悦豆兑换时长卡。') }}
                        </p>
                        <ul style="list-style-type: decimal;">
                            <li>
                                {{
                                    $t(
                                        '无论是购买还是兑换，都不支持退还时长卡。'
                                    )
                                }}
                            </li>
                            <li>
                                {{
                                    $t(
                                        '购买或兑换成功后，获得的时长卡可在“我的时长卡”查看，购买获得时长卡有效期为1年，悦豆兑换时长卡有效期为3月，请注意查看使用期限并合理安排使用。'
                                    )
                                }}
                            </li>
                        </ul>
                    </div>
                </el-popover>
            </div>

            <div class="store-wrapper" v-loading="shopLoading">
                <div class="sub-title">
                    <img class="mr10" src="../../assets/image/shop/purchase-icon.png" alt="直接买" />
                    <span>{{ $t('直接买') }}</span>
                </div>
                <div class="purchase-wrapper">
                    <store-item
                        class="store-item"
                        v-for="item in purchaseList"
                        :key="item.goodsId"
                        type="purchase"
                        :goods="item"
                        @purchase="handlePurchase"
                    ></store-item>
                </div>
                <div class="sub-title" style="margin-top: 20px;">
                    <img class="mr10" src="../../assets/image/shop/yuedou-exchange.png" alt="悦豆换" />
                    <span>{{ $t('悦豆换') }}</span>
                </div>
                <div class="purchase-wrapper">
                    <store-item
                        class="store-item"
                        v-for="item in goodsList"
                        :key="item.goodsId"
                        type="redeem"
                        :goods="item"
                        @redeem="handleRedeem"
                    ></store-item>
                </div>
            </div>

            <div slot="footer" class="shop-footer">
                <div class="check-detail" @click="linkToDetail">
                    {{ $t('查看明细') }}
                </div>
                <div class="coin-count">
                    <span class="coin-count-text">{{ $t('拥有') }}：</span>
                    <el-popover trigger="hover">
                        <div
                            slot="reference"
                            class="coin-total"
                            @mouseenter="coinTipHover"
                        >
                            <img
                                src="../../assets/image/shop/yuelu-coin.png"
                                alt="悦豆"
                            />
                            <span class="coin-count-num">{{
                                allCoinNumber
                            }}</span>
                        </div>
                        <div class="shop-tip">
                            <h3 style="text-align: center">
                                {{ $t('悦豆说明') }}
                            </h3>
                            <p>
                                {{
                                    $t(
                                        '悦豆是悦录里的积分。参与悦录的活动就能得到悦豆，无法通过充值获得。用户可以累积悦豆，进而在商城内兑换各种道具。'
                                    )
                                }}
                            </p>
                            <ul>
                                <li>
                                    {{
                                        $t(
                                            '获取：用户仅能通过每日签到、参与其他任务和活动获得悦豆。不能购买获得。'
                                        )
                                    }}
                                </li>
                                <li>
                                    {{
                                        $t(
                                            '使用：用户可以在商场使用悦豆兑换各种道具，如时长卡等。悦豆不支持提现，也不会过期。'
                                        )
                                    }}
                                </li>
                                <li>
                                    {{
                                        $t(
                                            '公平性：为保证公平性，悦豆系统的规则会根据实际运营情况进行适时的调整。关于悦豆政策变更的通知将以站内信的方式进行公告，请大家及时关注。'
                                        )
                                    }}
                                </li>
                            </ul>
                        </div>
                    </el-popover>
                </div>
            </div>
        </el-dialog>

        <redeem
            :redeemVis="redeemDialogVis"
            :goods="selectedGoods"
            @beforeClose="redeemClose"
        ></redeem>

        <cashier-dialog
            :cashierVis="cashierVis"
            :id="selectedGoodsId"
            :title="$t('获取更多时长')"
            :defaultTab="1"
            @beforeClose="cashierVis = false"
            @close="handleClose"
        ></cashier-dialog>
    </div>
</template>

<script>
import CashierDialog from '@/components/Order/CashierDialog';
import StoreItem from '@/components/Shop/StoreItem';
import Redeem from './Redeem.vue';
import createRewardBox from '@/extend/rewardBox';
import { fetchGoods } from '@/api/signIn-api';
import { fetchGoods as fetchPurchaseGoods } from '@/api/order-api';
import { mapState } from 'vuex';

export default {
    props: {
        shopVis: {
            type: Boolean,
            default: false
        }
    },
    components: {
        StoreItem,
        Redeem,
        CashierDialog
    },
    data() {
        return {
            cashierVis: false,
            shopLoading: false,
            page: 1,
            pageSize: 9999,
            total: 0,
            goodsList: [],

            purchaseList: [],

            selectedGoodsId: -1,

            redeemDialogVis: false,
            selectedGoods: {
                discount: 0,
                duration: 0,
                effectiveTime: 0,
                exchangeCoin: 0,
                goodsId: 1,
                goodsIntroduce: null,
                goodsName: '半小时转写时长卡',
                goodsPicPath: null,
                originalCoin: 0
            }
        };
    },
    mounted() {
        if (this.status) {
            this.getPurchaseGoods();
            this.getGoods();
        }
    },
    methods: {
        /**
         * 购买商品回调
         * @param {Number} duration 购买时长
         */
        handleClose(_, duration) {
            this.cashierVis = false;
            const reward = {
                type: 1,
                num: duration,
                amount: 1
            };
            const rewardBox = createRewardBox(
                document.querySelector('#app'),
                reward
            );
            rewardBox.show();
        },
        /**
         * 获取商品列表
         */
        async getPurchaseGoods() {
            try {
                const res = await fetchPurchaseGoods();
                this.purchaseList = res.data || [];
            } catch (error) {
                console.log('获取商品列表失败：', error);
            }
        },
        shopTipHover() {
            // 埋点
            window.weblog.report({
                id: 'voiceclub_idyun_webShop_shop_shopNote',
                action: 'show'
            });
        },
        coinTipHover() {
            // 埋点
            window.weblog.report({
                id: 'voiceclub_idyun_webShop_shop_beansNote',
                action: 'show'
            });
        },
        linkToDetail() {
            this.beforeClose();
            if (
                this.$route.path === '/personalInfo' &&
                this.$route.query.menu === 'coinDetail'
            )
                return;

            // 埋点
            window.weblog.report({
                id: 'voiceclub_idyun_webShop_shop_myBeans',
                action: 'click'
            });

            setTimeout(() => {
                this.$router.push({
                    path: 'personalInfo',
                    query: {
                        menu: 'coinDetail'
                    }
                });
            }, 200);
        },
        redeemClose() {
            this.redeemDialogVis = false;
        },
        handlePurchase(goods) {
            this.selectedGoodsId = goods.goodsId;
            this.cashierVis = true;
        },
        handleRedeem(goods) {
            this.selectedGoods = goods;
            this.redeemDialogVis = true;
            this.$store.commit('setRedeemVis', true);
        },
        beforeClose() {
            this.$emit('beforeClose');
        },
        async getGoods() {
            try {
                this.shopLoading = true;
                const res = await fetchGoods({
                    page: this.page,
                    pageSize: this.pageSize
                });
                const { list, total } = res.data;
                this.goodsList = list;
                this.total = total;
            } catch (error) {
                console.log(error);
            }
            this.shopLoading = false;
        }
    },
    computed: {
        ...mapState({
            allCoinNumber: state => state.allCoinNumber,
            status: state => state.status,
            redeemVis: state => state.redeemVis
        })
    },
    watch: {
        redeemVis(newVal) {
            if (!newVal) this.redeemClose();
        }
    }
};
</script>

<style scoped lang="less">
.shop-dialog /deep/ .el-dialog {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 auto !important;
    padding: 28px;
    width: 66.6667%;
    min-width: 800px;
    max-width: 1000px;
    aspect-ratio: 960 / 620;
    background-color: #f7f9fa;
    border-radius: 12px;
    .el-dialog__header {
        padding: 0;
        font-weight: 600;
        font-size: 16px;
        color: #1d2129;
        .shop-header {
            .tip-icon {
                margin-left: 8px;
                vertical-align: middle;
                cursor: pointer;
            }
        }
    }
    .el-dialog__body {
        padding: 0;
        height: calc(100% - 51px);
        .store-wrapper {
            margin: 0 -28px;
            padding: 0 28px;
            height: 100%;
            max-height: 100%;
            overflow-y: auto;

            .sub-title {
                position: relative;
                display: flex;
                align-items: center;
                margin-bottom: 10px;
                font-size: 16px;
                font-weight: 600;
                color: #1d2129;
                text-align: left;
            }

            .purchase-wrapper {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                align-content: flex-start;
            }

            .store-item {
                margin-bottom: 16px;
                aspect-ratio: 290 / 130;
                width: calc((100% - 32px) / 3);
            }
        }
        .store-wrapper::-webkit-scrollbar {
            width: 4px;
            background-color: #e5e5e5;
        }
        .store-wrapper::-webkit-scrollbar-thumb {
            background-color: #b7b7b7;
            border-radius: 4px;
        }
        .store-wrapper::-webkit-scrollbar-track {
            -webkit-box-shadow: 0 0 0px #808080 inset;
        }
    }
    .el-dialog__footer {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 0;
        height: 56px;
        background-color: #ffffff;
        border-radius: 0 0 12px 12px;
        .shop-footer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 28px;
            height: 100%;
            font-size: 14px;
            border-top: 1px solid #e9e9e9;
            .check-detail {
                color: #1876ff;
                cursor: pointer;
            }
            .check-detail:hover {
                opacity: 0.8;
            }
            .coin-count {
                display: flex;
                align-items: center;
                .coin-count-text {
                    margin-right: 2px;
                    color: #848b95;
                }
                .coin-total {
                    padding: 8px;
                    border-radius: 8px;
                    cursor: pointer;
                    img {
                        width: 20px;
                        vertical-align: bottom;
                    }
                    .coin-count-num {
                        font-weight: 600;
                        font-size: 16px;
                        color: rgba(0, 0, 0, 0.85);
                    }
                }
                .coin-total:hover {
                    background-color: rgba(0, 0, 0, 0.04);
                }
            }
        }
    }
}
</style>
