<template>
    <div v-if="visible" class="reward-box" @click="(e) => closeBox(e)">
        <div class="content">
            <img
                class="reward-img"
                :src="reward.type === 0 ? yueluCoin : timeCard"
                alt="奖励"
            />
            <div class="reward-content">
                {{ countShow }}
            </div>
            <div class="reward-btn" @click="hide">Get</div>
            <img
                class="cancel-icon"
                src="../../assets/image/home/cancel.png"
                alt="关闭"
                @click="hide"
            />
        </div>
    </div>
</template>

<script>
import yueluCoin from '@/assets/image/shop/yuelu-coin.png';
import min30zh from '@/assets/image/shop/30min-zh.png';
import min30en from '@/assets/image/shop/30min-en.png';
import hour1zh from '@/assets/image/shop/1hour-zh.png';
import hour1en from '@/assets/image/shop/1hour-en.png';
import hour2zh from '@/assets/image/shop/2hour-zh.png';
import hour2en from '@/assets/image/shop/2hour-en.png';
import hour3zh from '@/assets/image/shop/3hour-zh.png';
import hour3en from '@/assets/image/shop/3hour-en.png';
import hour5zh from '@/assets/image/shop/5hour-zh.png';
import hour5en from '@/assets/image/shop/5hour-en.png';
import hour10zh from '@/assets/image/shop/10hour-zh.png';
import hour10en from '@/assets/image/shop/10hour-en.png';

const TIMECARDMAP = {
    30: [min30zh, min30en],
    1: [hour1zh, hour1en],
    2: [hour2zh, hour2en],
    3: [hour3zh, hour3en],
    5: [hour5zh, hour5en],
    10: [hour10zh, hour10en],
};
const HOURBYSECOND = 3600;
const MINUTEBYSECOND = 60;

export default {
    props: {
        reward: {
            type: Object,
            default: () => ({
                type: 0,
                num: 0,
                amount: 0,
            }),
        },
    },
    data() {
        return {
            visible: false,
            yueluCoin,
        };
    },
    methods: {
        closeBox(e) {
            const ele = e.target;
            if (ele.classList.contains('reward-box')) this.hide();
        },
        show() {
            this.visible = true;
        },
        hide() {
            this.visible = false;
            this.$store.commit('setRedeemVis', false);
        },
    },
    computed: {
        timeCard() {
            const { num } = this.reward;
            const hours = num / HOURBYSECOND;
            return TIMECARDMAP[hours >= 1 ? hours : 30][1];
        },
        countShow() {
            const { type, num, amount } = this.reward;
            if (type === 0) {
                return `V-Beans X ${num}`;
            } else {
                const hours = num / HOURBYSECOND;
                if (hours >= 1) {
                    return `${hours} h TimeCard X ${amount}`;
                } else {
                    return `${num / MINUTEBYSECOND} m TimeCard X ${amount}`;
                }
            }
        },
    },
};
</script>

<style scoped lang="less">
@keyframes scaleUp {
    from {
        transform: scale(0);
    }
    to {
        transform: scale(1);
    }
}

.reward-box {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 9999;
    .content {
        aspect-ratio: 600 / 403;
        width: 346px;
        background-image: url(../../assets/image/shop/reward-bg.png);
        background-repeat: no-repeat;
        background-size: contain;
        animation: scaleUp 0.5s forwards;
        .reward-img {
            width: 36.46%;
            transform: translateY(-50%);
        }
        .reward-content {
            position: absolute;
            left: 0;
            right: 0;
            top: 40%;
            font-weight: 600;
            font-size: 24px;
        }
        .reward-btn {
            position: absolute;
            left: 0;
            right: 0;
            top: 70%;
            margin: 0 auto;
            width: 59.95%;
            height: 50px;
            line-height: 50px;
            background: linear-gradient(123deg, #ff914a 0%, #ff3d1b 100%);
            border-radius: 25px;
            font-weight: 600;
            font-size: 14px;
            color: #ffffff;
            cursor: pointer;
        }
        .cancel-icon {
            position: absolute;
            right: 20px;
            top: 20px;
            cursor: pointer;
        }
    }
}
</style>
