<template>
    <el-button
        class="verify-code-btn"
        :loading="loading"
        :disabled="disabled"
        @click="onclick"
    >
        <slot></slot>
    </el-button>
</template>

<script>
export default {
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        onclick() {
            this.$emit('click');
        },
    },
};
</script>

<style scoped lang="less">
.verify-code-btn {
    width: 100% !important;
    height: 48px !important;
    background-color: rgba(24, 118, 255, .1) !important;
    border-radius: 12px !important;
    font-weight: bold !important;
    font-size: 16px !important;
    color: #1876ff !important;
    border: none !important;
}

.verify-code-btn:hover,
.verify-code-btn:focus,
.verify-code-btn:active {
    background-color: rgba(24, 118, 255, .1) !important; // 权重的原因不得不在伪元素状态下再次设置color
    opacity: 0.8 !important;
}

.verify-code-btn.is-disabled,
.verify-code-btn.is-disabled:hover,
.verify-code-btn.is-disabled:focus {
    background-color: #000000 !important;
    color: #ffffff !important;
    opacity: 0.15 !important;
    pointer-events: none !important;
}
</style>
