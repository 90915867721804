import Vue from 'vue';
import Router from 'vue-router';
import NotFound from '../page/NotFound';
import { HISTORY_BASE } from '@/config/Constants';
import { getMobileOs } from '@/utils/CommonUtils';

Vue.use(Router);

const router = new Router({
    routes: [
        {
            path: '/',
            name: 'MainPage',
            component: () =>
                import(/* webpackChunkName: 'MainPage' */ '@/page/MainPage'),
            meta: {
                title: 'VoiceCube - AI meeting note taker & fast audio transcription',
            },
        },
        {
            path: '/detail',
            name: 'VoiceDetail',
            component: () =>
                import(
                    /* webpackChunkName: 'VoiceDetail' */ '@/page/VoiceDetailNew'
                ),
            meta: {
                title: 'Detail',
            },
            props: (route) => ({
                timeLen: route.query.timeLen,
                fileId: route.query.fileId,
            }),
        },
        {
            path: '/home',
            name: 'HomePage',
            component: () =>
                import(/* webpackChunkName: 'HomePage' */ '@/page/HomePage'),
            meta: {
                title: 'VoiceCube - AI meeting note taker & fast audio transcription',
            },
            children: [
                {
                    path: '/audio',
                    name: 'Audio',
                    component: () =>
                        import(
                            /* webpackChunkName: 'AudioLayout' */ '@/page/audio-layout'
                        ),
                    meta: {
                        title: 'VoiceCube - AI meeting note taker & fast audio transcription',
                    },
                    redirect: '/audio-list',
                    children: [
                        {
                            path: '/audio-list',
                            name: 'AudioList',
                            component: () =>
                                import(
                                    /* webpackChunkName: 'AllAudioPage' */ '@/page/audio-list'
                                ),
                            meta: {
                                title: 'VoiceCube - AI meeting note taker & fast audio transcription',
                            },
                        },
                        {
                            path: '/recycle-bin',
                            name: 'RecycleBin',
                            component: () =>
                                import(
                                    /* webpackChunkName: 'RecycleBin' */ '@/page/RecycleBin'
                                ),
                        },
                    ],
                },
                {
                    path: '/recycleBin',
                    name: 'RecycleBin',
                    component: () =>
                        import(
                            /* webpackChunkName: 'RecycleBin' */ '@/page/RecycleBin'
                        ),
                    meta: {
                        title: 'VoiceCube - AI meeting note taker & fast audio transcription',
                    },
                },
                {
                    path: '/personalInfo',
                    name: 'PersonalInfo',
                    component: () =>
                        import(
                            /* webpackChunkName: 'PersonalInfo' */ '@/page/PersonalInfo'
                        ),
                    meta: {
                        title: 'VoiceCube - AI meeting note taker & fast audio transcription',
                    },
                },
                {
                    path: '/helpDocument',
                    name: 'HelpDocumentPage',
                    component: () =>
                        import(
                            /* webpackChunkName: 'HelpDocumentPage' */ '@/page/HelpDocumentPage'
                        ),
                    meta: {
                        title: 'VoiceCube - AI meeting note taker & fast audio transcription',
                    },
                },
                // 造音频
                // 命名均为VoiceClone是为了避免重定向导致重复点击造音频时被误认为路由已经发生了变化而无法拦截
                // 具体可见HXTopHeader的同路由拦截方式
                {
                    path: '/voiceClone',
                    redirect: '/textToVoice',
                    name: 'VoiceClone',
                    component: () =>
                        import(
                            /* webpackChunkName: 'VoiceClone' */ '@/page/voiceClone/Layout'
                        ),
                    children: [
                        {
                            path: '/textToVoice',
                            name: 'VoiceClone',
                            component: () =>
                                import(
                                    /* webpackChunkName: 'TextToVoice' */ '@/page/voiceClone/TextToVoice'
                                ),
                            meta: {
                                title: 'VoiceCube - AI meeting note taker & fast audio transcription',
                            },
                        },
                        {
                            path: '/myVoice',
                            name: 'VoiceClone',
                            component: () =>
                                import(
                                    /* webpackChunkName: 'MyVoice' */ '@/page/voiceClone/MyVoice'
                                ),
                            meta: {
                                title: 'VoiceCube - AI meeting note taker & fast audio transcription',
                            },
                        },
                        {
                            path: '/songConversion',
                            name: 'VoiceClone',
                            component: () =>
                                import(
                                    /* webpackChunkName: 'SongConversion' */ '@/page/voiceClone/SongConversion'
                                ),
                            meta: {
                                title: 'VoiceCube - AI meeting note taker & fast audio transcription',
                            },
                        },
                    ],
                },
                // 订单结算
                {
                    path: '/order',
                    name: 'Order',
                    component: () =>
                        import(
                            /* webpackChunkName: 'TranSatisfaction' */ '@/page/Order'
                        ),
                },
                {
                    path: '/vip',
                    name: 'Vip',
                    component: () =>
                        import(
                            /* webpackChunkName: 'TranSatisfaction' */ '@/page/Vip'
                        ),
                },
            ],
        },
        {
            path: '/detail',
            name: 'VoiceDetail',
            component: () =>
                import(
                    /* webpackChunkName: 'VoiceDetail' */ '@/page/VoiceDetailNew'
                ),
            meta: {
                title: 'Detail',
            },
            props: (route) => ({
                timeLen: route.query.timeLen,
                fileId: route.query.fileId,
            }),
        },
        {
            path: '/tranSatis',
            name: 'TranSatisfaction',
            component: () =>
                import(
                    /* webpackChunkName: 'TranSatisfaction' */ '@/page/TranSatisfaction'
                ),
        },
        {
            path: '/404',
            name: 'NotFound',
            component: NotFound,
            meta: {
                title: '404 Not Found',
            },
        },
        {
            path: '*',
            redirect: '/404',
        },
    ],
    mode: 'history',
    base: HISTORY_BASE,
});

const mobileOs = getMobileOs();

router.beforeEach((to, from, next) => {

    // 移动端
    if (to.path === '/' && mobileOs !== 2) {
        document.documentElement.style.minWidth = 'unset';
        document.querySelector('body').style.minWidth = 'unset';
    } else {
        document.documentElement.style.minWidth = '1200px';
        document.querySelector('body').style.minWidth = '1200px';
    }
    next();
});

export default router;
