<template>
    <div class="purchase-vip">
        <div class="vip-card-wrapper">
            <div
                :class="[
                    'vip-box',
                    index === 0 && 'no-pl',
                    index === 2 && 'no-pr'
                ]"
                v-for="(vipCard, index) in vipCardList"
                :key="vipCard.goodsId"
            >
                <vip-card
                    :data="vipCard"
                    :selected="curvipCard.goodsId === vipCard.goodsId"
                    @selected="handleSelect"
                ></vip-card>
            </div>
        </div>
        <div class="price-detail-title">
            <span>{{ $t('会员尊享权益') }}</span>
            <div class="link-to-vip" @click="linkToVip">
                <span>{{ $t('查看权益对比') }}</span>
                <img
                    src="../../assets/image/vip/right-arrow.png"
                    alt="查看权益对比"
                />
            </div>
        </div>
        <div v-if="vipCardList.length > 0" class="settlement-wrapper">
            <div class="member-privilege-wrapper">
                <member-privilege></member-privilege>
            </div>
            <div class="qr-code-wrapper">
                <pay-code-box
                    type="vip"
                    :goods="curvipCard"
                    @success="handleSuccess"
                ></pay-code-box>
            </div>
        </div>
    </div>
</template>

<script>
import VipCard from '@/components/Order/VipCard';
import MemberPrivilege from '@/components/Order/MemberPrivilege';
import PayCodeBox from '@/components/Order/PayCodeBox';
import { fetchGoods } from '@/api/order-api';
import { mapState } from 'vuex';
import { openWindow } from '@/utils/CommonUtils';

export default {
    components: {
        VipCard,
        MemberPrivilege,
        PayCodeBox
    },
    data() {
        return {
            vipCardList: [],
            curvipCard: {
                goodsId: -1,
                goodsName: '',
                price: '0',
                currency: '￥',
                originalPrice: '0'
            }
        };
    },
    mounted() {
        this.getGoods();
    },
    methods: {
        /**
         * 支付成功回调
         * @param {String} type 会员/时长卡
         */
        handleSuccess(type) {
            this.$emit('success', type);
        },
        /**
         * 新开页面打开会员中心
         */
        linkToVip() {
            const { href } = this.$router.resolve({
                name: 'Vip',
                path: '/vip',
            });
            openWindow(href);
        },
        handleSelect(card) {
            this.curvipCard = card;
        },
        /**
         * 获取商品列表
         */
        async getGoods() {
            try {
                const res = await fetchGoods({ goodsType: 1 });
                this.vipCardList = res.data || [];
                this.curvipCard = this.vipCardList[0];
            } catch (error) {
                console.log('获取商品列表失败：', error);
            }
        }
    },
    computed: {
        ...mapState({
            status: state => state.status
        })
    }
};
</script>

<style scoped lang="less">
.purchase-vip {
    display: flex;
    flex-direction: column;
    padding: 32px;

    .vip-card-wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-shrink: 0;
        width: 100%;
        overflow: hidden;

        .vip-box {
            flex-shrink: 0;
            padding: 0 6px;
            width: calc(100% / 3);
            cursor: pointer;
        }

        .vip-box.no-pl {
            padding-left: 0;
        }

        .vip-box.no-pr {
            padding-right: 0;
        }
    }

    .price-detail-title {
        position: relative;
        margin-top: 20px;
        margin-bottom: 12px;
        font-weight: 600;
        font-size: 16px;
        color: #272727;
        text-align: left;

        .link-to-vip {
            position: absolute;
            top: 50%;
            left: 540px;
            transform: translate(-100%, -50%);
            font-size: 14px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.45);
            cursor: pointer;

            img {
                width: 16px;
                vertical-align: middle;
            }
        }
    }

    .settlement-wrapper {
        display: flex;
        flex: 1;

        .member-privilege-wrapper {
            width: 540px;
            height: 100%;
        }
        .qr-code-wrapper {
            flex: 1;
            margin-left: 20px;
            height: 100%;
        }
    }
}
</style>
