<template>
    <div :class="['vip-card', selected && 'selected']" @click="selectCard">
        <div class="vip-name">{{ vipCard.goodsName }}</div>
        <div class="vip-price">
            <span>{{ vipCard.currency }}</span>
            <span class="vip-cur-price">{{ vipCard.price | formatPrice() }}</span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            default: {
                goodsId: '',
                goodsName: '',
                price: '',
                currency: '￥',
                originalPrice: ''
            }
        },
        selected: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            vipCard: null
        };
    },
    created() {
        // 重命名
        this.vipCard = this.data;
    },
    methods: {
        selectCard() {
            if (this.selected) return; // 防止点击同一个卡片多次
            this.$emit('selected', this.vipCard);
        }
    }
};
</script>

<style scoped lang="less">
.vip-card {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 16px 24px;
    width: 100%;
    height: 132px;
    background-color: #fffbf4;
    border-radius: 12px;
    font-weight: 600;
    font-size: 16px;
    color: #804e13;

    .vip-name {
        font-weight: 600;
        font-size: 16px;
    }

    .vip-price {
        font-size: 16px;

        .vip-cur-price {
            font-weight: 900;
            font-size: 40px;
        }
    }
}

.vip-card::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border: 2px solid #ffeccb;
    border-radius: 12px;
}

.vip-card.selected {
    background-image: url(../../assets/image/shop/vip-card-selected-bg.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border: none;
}

.vip-card.selected::after {
    display: none;
}
</style>
