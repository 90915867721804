<template>
    <div id="app" class="app app-main-container">
        <transition>
            <router-view class="app-main" ref="viewer" />
        </transition>

        <login-dialog
            :visible="loginDiaVis"
            :authType="authType"
            @authChange="authChange"
            @close="handleLoginDialogClose"
        ></login-dialog>

        <!-- 消息弹框 -->
        <!-- <message-dialog
            :visible="messageDialogVisible"
            @beforeClose="messageDialogVisible = false"
        ></message-dialog> -->

        <!-- 商城弹框 -->
        <!-- <shop-dialog
            v-if="shopDialogVis"
            :shopVis="shopDialogVis"
            @beforeClose="shopDialogVis = false"
        ></shop-dialog> -->

        <!-- 签到&活动弹框 -->
        <reward-dialog
            :rewardVis="rewardDialogVis"
            @beforeClose="rewardDialogVis = false"
            @openSign="openSign"
        ></reward-dialog>

        <!-- 邀请/被邀请礼包弹框 -->
        <!-- <el-dialog
            class="new-gift-dialog"
            :visible.sync="invitateGiftVis"
            title="Welcome to Voicecube"
            @close="openSign"
        >
            <div class="gift-wrapper">
                <img
                    class="new-gift-img"
                    src="./assets/image/time-card-gift-bg.png"
                    alt="Welcome to Voicecube"
                />
                <div class="duration-text">5 Hours</div>
                <div class="en-text">TIME CARD</div>
                <div class="logo-bottom">Time Card</div>
            </div>
            <div class="new-gift-text">5 Hours Time Card X 1</div>
            <el-button
                class="new-gift-btn"
                type="primary"
                @click="invitateGiftVis = false"
                >Get</el-button
            >
        </el-dialog> -->

        <!-- 新人登录 -->
        <el-dialog
            class="new-gift-dialog"
            :visible.sync="newGiftVis"
            title="Welcome to VoiceCube"
            @close="judgeInvitate"
        >
            <div class="gift-wrapper">
                <img
                    class="new-gift-img"
                    src="./assets/image/time-card-gift-bg.png"
                    alt="Welcome to VoiceCube"
                />
                <div class="duration-text">10 Hours</div>
                <div class="en-text">TIME CARD</div>
                <div class="logo-bottom">TIME CARD</div>
            </div>
            <div class="new-gift-text">10 Hours Time Card X 1</div>
            <el-button
                class="new-gift-btn"
                type="primary"
                @click="newGiftVis = false"
                >Get</el-button
            >
        </el-dialog>
    </div>
</template>

<script>
import './assets/app.css';
import { mapState } from 'vuex';
import { updateLogin } from './api/common-api';
import { getUserInfo, fetchAccountStatus } from './api/user-api';
import LoginDialog from '@/components/Auth';
import MessageDialog from '@/components/MessageDialog';
import ShopDialog from '@/components/Shop';
import RewardDialog from '@/components/Reward';
import { fetchUnreadSize } from '@/api/message-api';
import { fetchUserSignInfo } from '@/api/signIn-api';

const DELAYTIME = 1000;

export default {
    name: 'App',
    provide() {
        return {
            login: this.showLoginDialog,
            message: this.message,
            getUnreadSize: this.getUnreadSize,
            unreadSize: () => this.unreadSize,

            // 商城
            shop: this.shop,

            // 签到&活动
            reward: this.reward,
            initStatus: this.initStatus,
        };
    },
    components: {
        LoginDialog,
        MessageDialog,
        ShopDialog,
        RewardDialog,
    },
    data() {
        return {
            loginDiaVis: false,
            messageDialogVisible: false,
            error: false,
            toastMsg: '',
            logo: '',
            position: 0,
            showLogin: false,

            unreadSize: 0,

            // 商城
            shopDialogVis: false,

            // 签到&商城
            rewardDialogVis: false,

            // 邀请
            invitateGiftVis: false,

            // 新人
            newGiftVis: false,

            receiveSuccess: false,

            authType: 'login',
        };
    },
    created() {
        this.initStatus();
    },
    mounted() {
        window.addEventListener('beforeunload', this.beforeloadFn, false);
    },
    destroyed() {
        window.removeEventListener('beforeunload', this.beforeloadFn, false);
    },
    methods: {
        /**
         * 修改auth类型
         */
        authChange(authType) {
            this.authType = authType;
        },
        /**
         * 通过事件注册来处理登录框关闭事件
         */
        handleLoginDialogClose() {
            this.loginDiaVis = false;
        },
        // 商城
        shop() {
            this.shopDialogVis = true;
        },
        // 签到&活动
        reward() {
            this.rewardDialogVis = true;
        },
        async openSign() {
            try {
                const res = await fetchUserSignInfo();
                const { todaySign } = res.data;
                if (todaySign) return;
            } catch (error) {
                console.log(error);
            }

            const openSign = localStorage.getItem('openSign');
            if (openSign === '1' || openSign === null) {
                setTimeout(() => {
                    // this.reward();
                }, DELAYTIME);
                localStorage.setItem('openSign', 0);
            }
        },
        async getUnreadSize() {
            try {
                const res = await fetchUnreadSize();
                this.unreadSize = res.data;
            } catch (error) {
                console.log(error);
            }
        },
        message() {
            this.messageDialogVisible = true;
        },
        beforeloadFn(e) {
            localStorage.setItem('openSign', 1);
        },
        // 登录
        showLoginDialog(type = 'login') {
            this.authType = type;
            this.loginDiaVis = true;
            if (type === 'login') {
                window.weblog.report({
                    id: 'VC_vc_homepage_mainbutton_startforfree',
                    action: 'click',
                    domain: 'stat.ainvest.com',
                });
            }
        },
        async checkAccountStatus() {
            try {
                const res = await fetchAccountStatus();
                const status = res.data;
                // 0: 正常 1: 注销中
                if (status) {
                    /* 此次执行注销确认逻辑 */
                    /* 需要在检测到用户处于注销中状态之后进行弹框操作 */
                    const confirm = this.$confirm(
                        'Are you sure to delete account?',
                        '',
                        {
                            confirmButtonText: 'continue',
                            cancelButtonText: 'cancel',
                            type: 'warning',
                            showClose: false,
                            closeOnClickModal: false,
                            closeOnPressEscape: false,
                        }
                    )
                        .then(() => {
                            return true;
                        })
                        .catch(() => {
                            return false;
                        });
                    return confirm;
                } else {
                    /* 正常状态下调用原mounted周期逻辑 */
                    updateLogin();
                    const response = getUserInfo().then((res) => {
                        this.$store.dispatch('homepage/getStoreInfo');
                        this.$store.commit('setUserInfo', res.data);
                        this.getUnreadSize();
                        this.$store.dispatch('getUserAsset');
                        this.$store.dispatch('menu/fetchMenuList');

                        // 判断是否为新用户
                        const received = res.data.received;
                        this.receiveSuccess = res.data.receiveSuccess;
                        if (received) {
                            this.newGiftVis = true;
                        } else {
                            this.judgeInvitate();
                        }

                        return res;
                    });
                    return response;
                }
            } catch (error) {
                console.log(error);
            }
        },
        judgeInvitate() {
            // 判断是否邀请/被邀请的用户
            if (this.receiveSuccess) {
                this.invitateGiftVis = true;
            } else {
                this.openSign();
            }
        },
        // 初始化系统状态
        initStatus() {
            const current = this.$router.currentRoute;
            /* 回调执行注销中相关逻辑 */
            this.$store.dispatch('initStatus', this.checkAccountStatus);
            if (current.meta.title) {
                document.title = current.query.fileName
                    ? current.query.fileName
                    : current.meta.title;
            }
        },
    },
    computed: {
        ...mapState({
            status: (state) => state.status,
            loginShow: (state) => state.loginShow,
        }),
    },
    watch: {
        $route(to) {
            if (to.meta.title) {
                document.title = to.query.fileName
                    ? to.query.fileName
                    : to.meta.title;
            }
        },
        /**
         * 监听登录弹框的变化
         */
        loginShow(newVal) {
            if (newVal) {
                this.showLoginDialog();
            }
        },
    },
};
</script>

<style>
#app {
    text-align: center;
    color: rgba(0, 0, 0, 0.65);
    width: 100%;
    height: 100%;
}
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
</style>

<style scoped lang="less">
.new-gift-dialog /deep/ .el-dialog {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 auto !important;
    padding: 24px 0;
    width: 300px;
    background: linear-gradient(180deg, #dfeaff 0%, #ffffff 100%);
    border-radius: 20px;
    .el-dialog__header {
        padding: 0;
    }
    .el-dialog__title {
        font-weight: 600;
        font-size: 20px;
        color: #000000;
    }
    .el-dialog__body {
        margin-top: 28px;
        padding: 0;
    }

    .gift-wrapper {
        position: relative;
        margin: 0 auto 20px;
        width: 71%;

        .new-gift-img {
            width: 100%;
        }

        .duration-text {
            position: absolute;
            left: 20px;
            top: 15px;
            font-size: 20px;
            font-weight: 600;
            font-style: italic;
            color: #ffffff;
            letter-spacing: 2px;
        }

        .en-text {
            position: absolute;
            left: 20px;
            top: 50px;
            font-size: 12px;
            font-weight: 600;
            font-style: italic;
            color: #ffffff;
            letter-spacing: 2px;
        }

        .logo-bottom {
            position: absolute;
            left: 20px;
            bottom: 20px;
            padding: 2px 5px;
            font-size: 12px;
            font-weight: 600;
            font-style: italic;
            color: #4e9bfd;
            background-color: #ffffff;
            letter-spacing: 2px;
            border-radius: 5px;
        }
    }
    .new-gift-text {
        font-weight: 600;
        font-size: 16px;
        color: #000000;
    }
    .new-gift-btn {
        margin-top: 28px;
        width: 180px;
        height: 49px;
        border-radius: 24.5px;
    }
}
</style>
