import { hourFormat, hourFormat2 } from "../../utils/TimeUtils";
import { getLimit } from '../../api/common-api'
const state = {
    storeSpace: {
        used: 0,
        total: 1
    },
    transSpace: {
        used: 0,
        total: 1,
        usedTime: 0,
        totalTime: 1
    },
    authPage: []
}

const getters = {

}

const mutations = {
    storeInfo(state, data) {
        state.transSpace.used = hourFormat(data.timeUsedToday);
        state.transSpace.total = hourFormat2(data.timeLimitToday);
        state.transSpace.usedTime = data.timeUsedToday;
        state.transSpace.totalTime = data.timeLimitToday;
        state.transSpace.persent = (data.timeUsedToday / (data.timeLimitToday == 0 ? 1 : data.timeLimitToday)) * 100;
        state.storeSpace.used = hourFormat(data.timeUsed);
        state.storeSpace.total = hourFormat2(data.timeLimit);
        state.storeSpace.timeUsed = data.timeUsed;
        state.storeSpace.timeLimit = data.timeLimit;
        state.storeSpace.persent = (data.timeUsed / (data.timeLimit == 0 ? 1 : data.timeLimit)) * 100;
        state.authPage = data.auth === 1 ? ['caption'] : []
    }
}

const actions = {
    getStoreInfo(context, query) {
        let { rootState } = context;
        if (!rootState.status) {
            let data = {
                timeUsedToday: 0,
                timeLimitToday: 0,
                timeUsed: 0,
                timeLimit: 0
            };
            context.commit('storeInfo', data);
            return
        }
        getLimit({ userId: rootState.userId, source: 0 }).then((res) => {
            context.commit('storeInfo', res.data);
        })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
