<template>
    <div class="gift-exchange">
        <div class="reward-title">
            <div class="gift-exchange-title">Gift Pack Redemption</div>
            <div class="gift-exchange-desc">
                Enter Redemption code to claim rewards
            </div>
        </div>
        <activity :list="activityList"></activity>
        <div class="gift-exchange-wrapper">
            <div class="exchange-input">
                <el-input
                    class="pack-code-input"
                    v-model="packCode"
                    placeholder="Please fill in the redemption code"
                    :maxlength="8"
                ></el-input>
            </div>
            <el-button
                type="primary"
                round
                :loading="redeemLoading"
                style="margin-top: 60px; padding: 12px 40px; width: fit-content"
                @click="giftExchange"
                >Confirm</el-button
            >
        </div>

        <vip-popover
            :visible="visible"
            :duration="duration"
            @close="visible = false"
        ></vip-popover>
    </div>
</template>

<script>
import CheckInItem from './CheckInItem.vue';
import Activity from './activity.vue';
import VipPopover from '@/components/popover/VipPopover';
import { giftCodeExchange } from '@/api/signIn-api';
import createRewardBox from '@/extend/rewardBox';
import { mapState } from 'vuex';
import { getUserInfo } from '@/api/user-api';

export default {
    components: {
        CheckInItem,
        Activity,
        VipPopover,
    },
    data() {
        return {
            redeemLoading: false,
            rewardBox: null,

            curReward: {
                url: '',
                name: '',
                num: 0,
            },
            packCode: '',

            // 会员弹框
            duration: 30,
            visible: false,
        };
    },
    methods: {
        async giftExchange() {
            if (!this.packCode) {
                this.$message.error('Please fill in the redemption code');
                return;
            }
            if (this.packCode.length < 8) {
                this.$message.error('Please enter the correct redemption code');
                return;
            }
            try {
                this.redeemLoading = true;
                const res = await giftCodeExchange({
                    packCode: this.packCode,
                });
                const { rewardNumber, rewardType } = res.data;
                if (rewardType >= 101 && rewardType <= 199) {
                    this.duration = rewardNumber;
                    this.visible = true;
                } else {
                    const reward = {
                        type: rewardType,
                        num: rewardNumber,
                        amount: 1,
                    };
                    this.rewardBox = createRewardBox(
                        document.querySelector('#app'),
                        reward
                    );
                    this.rewardBox.show();
                }
                // 更新数据
                this.updateUserRight();
            } catch (error) {
                console.log(error);
            }
            this.redeemLoading = false;
        },
        updateUserRight() {
            getUserInfo().then((res) => {
                this.$store.dispatch('homepage/getStoreInfo');
                this.$store.commit('setUserInfo', res.data);
                this.$store.dispatch('getUserAsset');
            });
        },
    },
    computed: {
        ...mapState({
            activityList: (state) => state.activityList,
        }),
    },
};
</script>

<style scoped lang="less">
.gift-exchange {
    display: flex;
    flex-direction: column;
    height: 100%;
    .reward-title {
        position: relative;
        margin: 0 0 14px 0;
        text-align: left;
        .gift-exchange-title {
            font-family: 'DingTalk';
            font-size: 28px;
            font-weight: 600;
            color: #000000;
        }
        .gift-exchange-desc {
            margin-top: 14px;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.45);
        }
    }
    .gift-exchange-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;
        margin-top: 20px;
        overflow: hidden;
        background-color: #ffffff;
        border-radius: 28px;
        .exchange-input {
            margin-top: 80px;
            width: 80%;
            .pack-code-input /deep/ input {
                height: 60px;
                border-radius: 20px;
                border: none;
                background-color: #f7f9fa;
                color: rgba(0, 0, 0, 0.85);
            }
            .pack-code-input /deep/ input::placeholder {
                color: #475967;
                opacity: 0.4;
            }
        }
    }
}
</style>
