import _SpkInfo from './SpkInfo';
import store from '../../store';

export default {
    install(Vue) {
        let spkInfo = null;

        Vue.component(_SpkInfo.name, _SpkInfo);

        Vue.prototype.$spkInfo = {
            generate
        };

        function generate(section, props) {
            const SpkInfo = Vue.extend({
                render(h) {
                    return h('spk-info', { props });
                }
            });
            spkInfo = new SpkInfo({ store });
            this.vm = spkInfo.$mount();
            section.prepend(this.vm.$el);
        }
    }
};
