<template>
    <el-dialog
        :custom-class="mobileOs !== 2 ? 'mobile-dialog' : ''"
        :visible="visible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :before-close="beforeClose"
        destroy-on-close
    >
        <login-box
            v-if="authType === 'login'"
            @authChange="authChange"
        ></login-box>
        <sign-up-reset-box
            v-else
            :type="authType"
            @authChange="authChange"
        ></sign-up-reset-box>
    </el-dialog>
</template>

<script>
import LoginBox from '@/components/Auth/LoginBox';
import SignUpResetBox from '@/components/Auth/SignUpResetBox';
import { getMobileOs } from '@/utils/CommonUtils';

export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        authType: {
            type: String,
            default: 'login',
        },
    },
    components: {
        LoginBox,
        SignUpResetBox,
    },
    data() {
        return {
            mobileOs: 2,
        };
    },
    mounted() {
        // 根据设备型号决定展示哪种布局
        this.mobileOs = getMobileOs();
    },
    methods: {
        /**
         * 关闭前回调
         */
        beforeClose() {
            this.$emit('close');
        },
        /**
         * auth状态切换回调
         * @param {string} authType auth类型
         */
        authChange(authType) {
            this.$emit('authChange', authType);
        },
    },
};
</script>

<style scoped lang="less">
/deep/ .el-dialog {
    width: 592px;
    height: 672px;
    border-radius: 40px;
    overflow: hidden;
    .el-dialog__header {
        padding: 0;
    }

    .el-dialog__body {
        padding: 32px 66px;
        height: 100%;
    }
}

/deep/ .mobile-dialog {
    width: 100%;
    height: 608px;
    .el-dialog__body {
        padding: 32px 12px;
    }
}
</style>
