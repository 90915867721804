/* 主要用来实现用户训练完成模型跳转合成的功能，后续用到vuex再行调整 */
const state = {
    userModel: {
        // modelType
        mt: 1,
        // modelCode
        mc: ''
    }
};

const mutations = {
    setUserModel(state, data) {
        state.userModel = data;
    },
    removeUserModel(state) {
        state.userModel = {
            mt: 1,
            mc: ''
        };
    }
};

export default {
    namespaced: true,
    state,
    mutations
};
