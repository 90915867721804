<template>
    <div class="task">
        <div class="task-title-wrapper">
            <div class="task-title">{{ $t('悦录任务挑战') }}</div>
            <el-popover trigger="hover">
                <img
                    class="tip-icon"
                    slot="reference"
                    src="../../assets/image/shop/tip-icon.png"
                    alt="提示"
                    @mouseenter="signInTipHover"
                />
                <div class="shop-tip">
                    <h2 style="text-align: center">
                        {{ $t('任务挑战说明') }}
                    </h2>
                    <p>
                        {{
                            $t(
                                '用户可通过完成任务的方式获得悦豆/时长卡等奖励'
                            )
                        }}
                    </p>
                    <ul>
                        <li>
                            {{ $t('每日任务：每天都可以完成并获得奖励，凌晨00:00自动更新，请及时领取奖励') }}
                        </li>
                        <li>
                            {{
                                $t(
                                    '挑战任务：每个任务只能完成1次，完成后任务奖励不会过期'
                                )
                            }}
                        </li>
                    </ul>
                </div>
            </el-popover>
        </div>
        <activity :list="activityList"></activity>
        <div class="task-list" v-loading="taskLoading">
            <div class="dialy-task-title">{{ $t('每日任务') }}</div>
            <div
                class="task-item"
                v-for="item in dialyTaskList"
                :key="item.challengeId"
            >
                <task-item :data="item" @refreshList="getTask"></task-item>
            </div>
            <div class="challenge-task-title">{{ $t('挑战任务') }}</div>
            <div
                class="task-item"
                v-for="item in challengeTaskList"
                :key="item.challengeId"
            >
                <task-item :data="item" @refreshList="getTask"></task-item>
            </div>
        </div>
    </div>
</template>

<script>
import Activity from './activity.vue';
import TaskItem from '@/components/Reward/TaskItem';
import { mapState } from 'vuex';
import { fetchTask, getTaskReward } from '@/api/signIn-api';

export default {
    components: {
        Activity,
        TaskItem
    },
    data() {
        return {
            taskLoading: false,
            dialyTaskList: [],
            challengeTaskList: []
        };
    },
    mounted() {
        if (this.status) this.getTask();
    },
    methods: {
        signInTipHover() {},
        async getTask() {
            try {
                this.taskLoading = true;
                const res = await fetchTask();
                this.dialyTaskList = res.data[0].taskList;
                this.challengeTaskList = res.data[1].taskList;
            } catch (error) {
                console.log(error);
            }
            this.taskLoading = false;
        },
        async getReward({ taskId }) {
            try {
                await getTaskReward({ taskId });
            } catch (error) {
                console.log(error);
            }
        }
    },
    computed: {
        ...mapState({
            activityList: state => state.activityList,
            status: state => state.status
        })
    }
};
</script>

<style scoped lang="less">
.task {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    .task-title-wrapper {
        display: flex;
        align-items: center;
        margin: 0 0 14px 0;
        text-align: left;
        .task-title {
            font-family: 'DingTalk';
            font-size: 28px;
            font-weight: 600;
            color: #000000;
        }
        .tip-icon {
            margin-left: 12px;
            vertical-align: middle;
            cursor: pointer;
        }
    }
    .task-list {
        flex: 1;
        margin-top: 20px;
        padding: 24px 18px;
        background-color: #ffffff;
        border-radius: 28px;
        text-align: left;
        overflow: auto;
        .dialy-task-title,
        .challenge-task-title {
            margin-bottom: 16px;
            font-weight: 600;
            font-size: 16px;
            color: #222323;
        }
        .task-item {
            margin-bottom: 14px;
        }
    }
    .task-list::-webkit-scrollbar {
        width: 4px;
        background: opacity;
    }
    .task-list::-webkit-scrollbar-thumb {
        background-color: #b7b7b7;
        border-radius: 4px;
    }
    .task-list::-webkit-scrollbar-track {
        margin: 14px 0;
        -webkit-box-shadow: 0 0 0px #808080 inset;
    }
}
</style>
