import { Get, Post } from '../utils/HttpUtils'

/** 获取全部音频文件列表 */
export const getAllAudios = (params) => Get('/api/v1/audio/list', {...params, source: 0})

/** 获取未转写音频 */
export const getUntransferAudio = (params) => Get('/api/v2/audio/no_transfer_list', params)

/** 单文件迁移听单 */
export const moveAudio = (params) => Post('/api/v2/audio/move', params)

/** 多文件批量迁移听单 */
export const batchMoveAudio = (params) => Post('/api/v2/audio/move_batch', params)
