import { Get, PostJson, Post, PostWWW } from '../utils/HttpUtils';

/** 获取文件状态 */
export const getFileInfo = (params) =>
    Get('/api/v1/audio/info', params);

/** 更新记录 */
export const updateRecord = (params) =>
    Post('/api/v1/audio/record/update_record', params);

/** 获取文本信息 */
// export const getTextInfo = (params) => Get('/api/v1/audio/text/get_text', params)
export const getTextInfo = (params) =>
    Get('/api/v2/audio/text/get_text', { answerVersion: 3, ...params });

/** 导出TXT */
export const downloadTxt = (params) =>
    Get('/api/v3/audio/text/export_txt', params, {
        responseType: 'blob',
    });

/** 导出Word */
export const downloadWord = (params) =>
    Get('/api/v3/audio/text/export_word', params, {
        responseType: 'blob',
    });

/** 下载多条音频 */
export const downloadVoice = (params) =>
    Get('/api/v1/audio/download/batch', params, {
        responseType: 'blob',
    });

/** 下载单条音频 */
export const downloadSingleVoice = (params) =>
    Get('/api/v3/audio/download/', params, {
        responseType: 'blob',
    });

/** 保存文本信息 */
export const saveContent = (data) =>
    PostJson('/api/v1/audio/text/update_text', data);

/** 获取转写进度 */
export const getTransferProgress = (params) =>
    Get('/api/v1/dictation/progress/get_progress', params);

/** 分段获取音频数据url */
export const getAudioBytesUrl = '/voice_yuelu/api/v1/audio/bytes/get_part';

/** 获取音频信息 */
export const getAudioInfo = (params) => Get('/api/v1/audio/info', params);

/** 检查音频状态 */
export const checkAudioStatus = (params) =>
    Get('/api/v1/audio/check_audio', params);

/**
 * 获取富文本内容
 */
export const fetchRichText = (data) => PostWWW('/rich_text/get_text', data);

/**
 * 保存富文本内容
 */
export const updateRichText = (data) => PostWWW('/rich_text/update_text', data);

/**
 *  保存章节速览
 */
export const updatePara = (data) => PostWWW('/text/update_para', data);

/**
 *  保存摘要
 */
export const updateSummary = (data) => PostWWW('/text/update_abstracts', data);

/**
 *  导出摘要
 */
export const exportSummary = (params) =>
    Get('/text/abstracts_export_word', params, {
        responseType: 'blob',
    });

/**
 *  导出富文本
 */
export const exportRichText = (params) =>
    Get('/rich_text/rich_export_word', params, {
        responseType: 'blob',
    });

/**
 * 富文本图片保存
 * @param fileId fileId
 * @param s3Key s3Key
 */
export const fetchImgUrl = (params) => Get('/rich_text/generate_url', params);
