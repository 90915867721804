<template>
    <div v-if="visible" class="vip-toast-box">
        <div class="content">
            <img
                class="reward-img"
                src="../../assets/image/vip/vip-card.png"
                alt="奖励"
            />
            <div class="text">
                <span>{{ 'Get ' + formatDuration + ' Pro' }}</span>
            </div>
            <div class="confirm-btn" @click="handleClose">Get</div>
            <img
                class="cancel-icon"
                src="../../assets/image/home/cancel.png"
                alt="关闭"
                @click="handleClose"
            />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        duration: {
            type: Number,
            default: 1,
        },
    },
    methods: {
        handleClose() {
            this.$emit('close');
        },
    },
    computed: {
        formatDuration() {
            const year = this.duration / 30 / 12;
            if (year >= 1) {
                return `${Math.round(year)}${year > 1 ? 'years' : 'year'}`;
            } else {
                return `${Math.round(this.duration / 30)} ${
                    Math.round(this.duration / 30) > 1 ? 'months' : 'month'
                }`;
            }
        },
    },
};
</script>

<style scoped lang="less">
@keyframes scaleUp {
    from {
        transform: scale(0);
    }
    to {
        transform: scale(1);
    }
}

.vip-toast-box {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 9999;

    .content {
        aspect-ratio: 600 / 520;
        width: 380px;
        border-radius: 20px;
        background: linear-gradient(182deg, #ffefcf 0%, #ffffff 100%);
        animation: scaleUp 0.5s forwards;

        .reward-img {
            margin-top: 27px;
            width: 230px;
            aspect-ratio: 300 / 210;
        }

        .text {
            font-weight: 600;
            font-size: 20px;
            color: #000000;
        }

        .confirm-btn {
            margin: 28px auto 0;
            width: 180px;
            height: 50px;
            line-height: 50px;
            background: linear-gradient(137deg, #805e3e 0%, #5b422b 100%);
            border-radius: 11px;
            font-weight: 600;
            font-size: 16px;
            color: #ffffff;
            cursor: pointer;
        }
        .cancel-icon {
            position: absolute;
            right: 20px;
            top: 20px;
            cursor: pointer;
        }
    }
}
</style>
