<template>
    <div class="price-detail">
        <ul class="price-list">
            <li class="price-item">
                <div>{{ $t('商品金额') }}</div>
                <div>￥{{ price }}</div>
            </li>
            <!-- <li class="price-item">
                <div>{{ $t('悦录会员专享立减') }}</div>
                <div>-￥0.59</div>
            </li>
            <li class="price-item">
                <div>{{ $t('优惠劵立减') }}</div>
                <div>{{ $t('不可用') }}</div>
            </li> -->
        </ul>

        <div class="total-price-wrapper">
            <div class="total-text">{{ $t('合计') }}：</div>
            <div class="total-price">￥{{ price }}</div>
        </div>

        <div class="purchase-notes">
            <div>{{ $t('购买说明') }}：</div>
            <div>1.{{ $t('购买的商品不支持退还。') }}</div>
            <div>
                2.{{
                    $t(
                        '购买成功后，可在“我的时长卡”查看，购买获得时长卡有效期为1年。'
                    )
                }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        price: {
            type: String,
            default: '0'
        }
    }
};
</script>

<style scoped lang="less">
.price-detail {
    padding: 20px;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    border: 2px solid #f5f5f5;

    .price-list {
        list-style-type: none;
        margin-bottom: 40px;
        // height: 126px;

        .price-item {
            display: flex;
            justify-content: space-between;
            font-size: 14px;
            color: #272727;
        }
    }

    .total-price-wrapper::after {
        content: '';
        position: absolute;
        top: -4px;
        left: 0;
        right: 0;
        height: 1px;
        background-color: #ebeaea;
    }

    .total-price-wrapper {
        position: relative;
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        color: #272727;

        .total-text {
            font-size: 14px;
        }

        .total-price {
            font-weight: 600;
            font-size: 20px;
        }
    }
    
    .purchase-notes {
        margin-top: 20px;
        text-align: left;
    }
}
</style>
