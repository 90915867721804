import { Get, Put, Post } from '../utils/HttpUtils';

export const startTransferBatch = data =>
    Post('/api/v2/dictation/audio/dictation_batch', {
        dictationParam: JSON.stringify({
            ...data,
            source: 0,
            answerVersion: 3
        }),
        source: 0
    });

/** 获取热词 */
export const getHotWords = () => Get('/api/v1/hotWords');

/** 更新热词 */
export const updateHotWords = data => Put('/api/v1/hotWords', data);

/** 获取配置项 */
export const getConfiguration = () => Get('/api/v1/dictation/configuration');

/** 获取预览文本 */
export const getPreviewText = (params) => Get('/api/v3/audio/text/get_text_preview', params);
