<template>
    <div class="check-in">
        <div class="reward-title">
            <div class="sign-in-title">{{ $t('悦录每日签到') }}</div>
            <el-popover trigger="hover">
                <img
                    class="tip-icon"
                    slot="reference"
                    src="../../assets/image/shop/tip-icon.png"
                    alt="提示"
                    @mouseenter="signInTipHover"
                />
                <div class="shop-tip">
                    <h2 style="text-align: center">
                        {{ $t('每日签到说明') }}
                    </h2>
                    <ul>
                        <li>
                            {{ $t('每日签到领取当日奖励，如悦豆及时长卡。') }}
                        </li>
                        <li>
                            {{
                                $t(
                                    '每日签到在每天00:00刷新，如漏签不支持补签。'
                                )
                            }}
                        </li>
                        <li>
                            {{
                                $t(
                                    '签到进度在每月1号00:00刷新，仅当月有效。从1号开始按顺序签到，签到天数越多，拿到的奖励越多。'
                                )
                            }}
                        </li>
                    </ul>
                </div>
            </el-popover>
        </div>
        <div class="check-in-wrapper">
            <div class="accumulate">
                {{ nowMonth }}{{ $t('月已累计签到')
                }}<span style="font-weight: 600; color: #ff3c1a">
                    {{ allSignDay }} </span
                >{{ $t('天') }}
            </div>
            <activity :list="activityList"></activity>
            <div class="list-wrapper" v-loading="checkInLoading">
                <ul class="check-in-list">
                    <li
                        :class="[
                            'check-in-li',
                            (index + 1) % 7 !== 0 && 'has-mr',
                            index + 1 <= 28 && 'has-mb'
                        ]"
                        v-for="(item, index) in rewardContentList"
                        :key="index"
                    >
                        <check-in-item
                            :status="checkInStatus(index)"
                            :day="item.day"
                            :rewardNum="item.signRewardNumber"
                            :rewardType="item.signRewardType"
                            :rewardPicPath="item.rewardPicPath"
                            @checkIn="checkIn"
                        ></check-in-item>
                    </li>
                </ul>
                <div
                    v-loading="isSigning"
                    :class="['check-in-btn', todaySign && 'checked']"
                    @click="checkIn"
                >
                    {{
                        todaySign
                            ? $t('已签到')
                            : vip
                            ? $t('会员双倍')
                            : $t('签到')
                    }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CheckInItem from './CheckInItem.vue';
import Activity from './activity.vue';
import { fetchUserSignInfo, sign } from '@/api/signIn-api';
import createRewardBox from '@/extend/rewardBox';
import { throttle } from 'lodash';
import { mapState } from 'vuex';

export default {
    components: {
        CheckInItem,
        Activity
    },
    data() {
        return {
            checkInLoading: false,
            allSignDay: 5,
            nowMonth: 1,
            rewardContentList: [],
            todaySign: false,
            isSigning: false,
            rewardBox: null,

            curReward: {
                url: '',
                name: '',
                num: 0
            }
        };
    },
    mounted() {
        if (this.status) this.getUserSignInfo();

        // 埋点
        window.weblog.report({
            id: 'voiceclub_idyun_webZSC_dailyCheckIn_dailyCheckIn',
            action: 'click'
        });
    },
    methods: {
        signInTipHover() {
            // 埋点
            window.weblog.report({
                id: 'voiceclub_idyun_webZSC_dailyCheckIn_checkInNote',
                action: 'show'
            });
        },
        async getUserSignInfo() {
            try {
                this.checkInLoading = true;
                const res = await fetchUserSignInfo();
                const {
                    allSignDay,
                    nowMonth,
                    rewardContentList,
                    todaySign
                } = res.data;
                this.allSignDay = allSignDay;
                this.nowMonth = nowMonth;
                this.rewardContentList = rewardContentList;
                this.todaySign = todaySign;
            } catch (error) {
                console.log(error);
            }
            this.checkInLoading = false;
        },
        checkInStatus(index) {
            if (index + 1 <= this.allSignDay) {
                return 0;
            } else if (index === this.allSignDay && !this.todaySign) {
                return 1;
            } else {
                return 2;
            }
        },
        checkIn: throttle(async function() {
            if (this.isSigning) return;
            try {
                this.isSigning = true;
                const res = await sign();
                const {
                    signCheck,
                    signRewardNumber,
                    signRewardType,
                    memberTimes
                } = res.data;
                /* 0: 签到失败 1: 签到成功 2:  2: 已经签到 */
                if (signCheck === 1) {
                    const reward = {
                        type: signRewardType,
                        num: signRewardNumber,
                        amount: memberTimes || 1
                    };
                    this.rewardBox = createRewardBox(
                        document.querySelector('#app'),
                        reward
                    );
                    this.rewardBox.show();
                    this.$store.dispatch('getUserAsset');
                    await this.getUserSignInfo();
                } else if (signCheck === 2) {
                    this.$message.info(this.$t('今日已签过了哦'));
                    await this.getUserSignInfo();
                } else {
                    this.$message.error(this.$t('签到失败，待会再试试吧'));
                }
            } catch (error) {
                console.log(error);
            }
            this.isSigning = false;

            // 埋点
            window.weblog.report({
                id: 'voiceclub_idyun_webZSC_dailyCheckIn_getItem',
                action: 'click'
            });

            // 埋点
            window.weblog.report({
                id: 'voiceclub_idyun_webZSC_dailyCheckIn_checkIn',
                action: 'click'
            });
        }, 2000)
    },
    computed: {
        ...mapState({
            status: state => state.status,
            activityList: state => state.activityList,
            vip: state => state.userInfo.isMember
        })
    }
};
</script>

<style scoped lang="less">
.check-in {
    display: flex;
    flex-direction: column;
    height: 100%;
    .reward-title {
        display: flex;
        align-items: center;
        margin: 0 0 14px 0;
        text-align: left;
        .sign-in-title {
            font-family: 'DingTalk';
            font-size: 28px;
            font-weight: 600;
            color: #000000;
        }
        .tip-icon {
            margin-left: 12px;
            vertical-align: middle;
            cursor: pointer;
        }
    }
    .check-in-wrapper {
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow: hidden;
        .accumulate {
            margin-bottom: 10px;
            font-size: 16px;
            color: #3f5b0d;
            text-align: left;
        }
        .list-wrapper {
            flex: 1;
            margin-top: 20px;
            padding: 12px;
            background-color: #ffffff;
            border-radius: 28px;
            .check-in-list {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                list-style-type: none;
                .check-in-li {
                    width: calc((100% - 48px) / 7);
                }
                .check-in-li.has-mr {
                    margin-right: 8px;
                }
                .check-in-li.has-mb {
                    margin-bottom: 8px;
                }
            }
            .check-in-btn {
                margin: 0 auto;
                margin-top: 28px;
                padding: 13px 49px;
                width: fit-content;
                background: linear-gradient(123deg, #ff914a 0%, #ff3d1b 100%);
                box-shadow: 0px 8px 17px 0px #ffc9a6;
                border-radius: 55px;
                font-weight: 500;
                color: #ffffff;
                cursor: pointer;
                letter-spacing: 2px;
            }
            .check-in-btn.checked {
                background: #bbbfc3;
                pointer-events: none;
                box-shadow: none;
            }
            .check-in-btn:hover {
                opacity: 0.8;
            }
        }
    }
}
</style>
