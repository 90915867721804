import { Get, PostForm, Post } from '../utils/HttpUtils';

/** 获取听单列表
 * type 0 全部, 1 未转写
 */
export const getMenuList = params =>
    Get('/api/v2/menu/sound_list?source=0', params);

/** 增加听单 */
export const addMenu = data => PostForm('/api/v2/menu/create', data);

/** 重命名听单 */
export const renameMenu = data => PostForm('/api/v2/menu/update', data);

/** 删除听单 */
export const deleteMenu = data => PostForm('/api/v2/menu/delete', data);

/** 更新最后登录时间 */
export const updateLogin = () =>
    Post('/api/v1/user/login/last_time', { source: 0 });

/** 创建音频记录 */
export const addRecord = params =>
    Post('/api/v1/audio/record/create_record', params);

/** 删除音频记录 */
export const deleteRecord = params =>
    Post('/api/v1/audio/record/delete_record', params);

/** 批量删除记录 */
export const batchDeleteRecord = params =>
    Post('/api/v1/audio/record/delete_batch', params);

/** 获取用户容量使用情况 */
export const getLimit = params => Get('/api/v1/limit/time/get_limit', params);

/** 获取底部链接 */
export const getApiLinks = () => Get('/api/v1/index/link/get_link?type=1');
