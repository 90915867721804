var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{attrs:{"custom-class":"message-dialog","visible":_vm.visible,"width":"960px","before-close":_vm.beforeClose},on:{"update:visible":function($event){_vm.visible=$event}}},[_c('div',{staticClass:"message-list"},[_c('div',{staticClass:"message-header"},[_c('div',{staticClass:"message-txt"},[_c('span',[_vm._v(" Message"+_vm._s(_vm.unreadSize() > 0 ? `(${_vm.formatUnreadSize})` : '')+" ")]),_c('el-tooltip',{attrs:{"effect":"dark","content":"Read all"}},[(_vm.unreadSize() > 0)?_c('img',{attrs:{"src":require("../assets/image/read-all.png")},on:{"click":_vm.handleReadAll}}):_vm._e()])],1),_c('div',{staticStyle:{"padding-top":"1px","height":"30px","line-height":"30px"}},[_c('span',{staticClass:"sort-text",style:({
                        color: _vm.readSign === 0 ? '#1876ff' : '#4f5967'
                    }),on:{"click":_vm.changeSign}},[_vm._v("未读")])])]),_c('ul',{staticClass:"message-body"},_vm._l((_vm.msgList),function(msg,index){return _c('li',{key:index,class:[
                    'message-box',
                    _vm.selectedMsg === msg ? 'selected' : ''
                ],on:{"click":function($event){return _vm.handleRead(msg)}}},[_c('div',{staticClass:"message-icon"},[_c('img',{attrs:{"src":require("../assets/image/message-icon.png")}})]),_c('div',{staticClass:"voice-info"},[_c('div',{staticClass:"message-type-time"},[_c('div',{staticClass:"message-type"},[(!msg.notifyRead)?_c('img',{attrs:{"src":require("../assets/image/dot-unread.png")}}):_vm._e(),_c('span',{class:[msg.notifyRead ? 'read' : '']},[_vm._v(_vm._s(msg.senderName))])]),_c('div',{staticClass:"message-time"},[_vm._v(" "+_vm._s(_vm._f("messageDateFormate")(msg.sendTimeStr,_vm.$i18n.locale))+" ")])]),_c('div',{class:[
                            'message-title',
                            msg.notifyRead ? 'read' : ''
                        ]},[_vm._v(" "+_vm._s(msg.notifyTitle)+" ")]),_c('div',{staticClass:"message-detail-split"},[_vm._v(" "+_vm._s(msg.notifyText)+" ")])])])}),0)]),_c('div',{staticClass:"message-detail"},[(_vm.selectedMsg)?_c('div',{staticClass:"is-reading"},[_c('div',{staticClass:"detail-header"},[_c('div',{staticClass:"message-icon"},[_c('img',{attrs:{"src":require("../assets/image/message-icon.png")}})]),_c('div',{staticClass:"detail-info"},[_c('div',[_c('div',{staticClass:"detail-type"},[_vm._v(" "+_vm._s(_vm.selectedMsg.senderName)+" ")]),_c('div',{staticClass:"send-to-me"},[_vm._v("发给我")])]),_c('div',{staticClass:"detail-time"},[_vm._v(" "+_vm._s(_vm._f("messageDateFormate")(_vm.selectedMsg.sendTimeStr))+" ")])])]),_c('div',{staticClass:"detail-box"},[_c('div',{staticClass:"detail-title"},[_vm._v(" "+_vm._s(_vm.selectedMsg.notifyTitle)+" ")]),_c('div',{staticClass:"detail-content"},[_c('span',[_vm._v(_vm._s(_vm.selectedMsg.notifyText))])])])]):_c('div',{staticClass:"no-reading"},[_c('img',{attrs:{"src":require("../assets/image/fail-icon.png")}})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }