<template>
    <div class="pay-code-box">
        <div class="pay-tabs">
            <div
                :class="['alipay', 'pay-tab', channel === 'alipay' && type]"
                @click="payTabChange('alipay')"
            >
                <img
                    src="../../assets/image/shop/alipay-icon.png"
                    alt="支付宝支付"
                    style="vertical-align: middle"
                />
                <span>支付宝支付</span>
            </div>
            <!-- <div
                :class="['wxpay', 'pay-tab', channel === 'wechat' && type]"
                @click="payTabChange('wechat')"
            >
                <img
                    src="../../assets/image/shop/wechat-icon.png"
                    alt="微信支付"
                    style="vertical-align: middle"
                />
                <span>{{ $t('微信支付') }}</span>
            </div> -->
        </div>
        <div class="pay-content">
            <div class="qr-code-warpper" v-loading="qrCodeLoading">
                <iframe
                    v-if="payCodeUrl"
                    title="支付二维码"
                    :src="payCodeUrl"
                    frameborder="no"
                    border="0"
                    marginwidth="0"
                    marginheight="0"
                    scrolling="no"
                    width="140"
                    height="140"
                    style="overflow: hidden"
                ></iframe>
                <div v-if="maskTip" class="qr-code-mask" @click="handleRefresh">
                    {{ maskTip }}
                </div>
            </div>
            <div class="qr-code-status">
                <div v-if="type === 'vip'" class="agreement">
                    <span>我已阅读并同意</span>
                    <span class="pointer" @click="linkToMembership">《会员服务协议》</span>
                </div>
                <div v-if="tradeStatus === 0" class="trade-tip">
                    扫码成功，请及时支付
                </div>
                <div v-if="tradeStatus === 3" class="trade-tip red">
                    支付失败，请重新支付
                </div>
                <div class="price">
                    <span>扫码支付</span>
                    <span style="color: #ff3549">{{ goods.currency }}</span>
                    <span
                        style="font-weight: 900; font-size: 23px; color: #ff3549"
                        >{{ goods.price | formatPrice() }}</span
                    >
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { fetchPayCodeUrl, fetchOrderStatus } from '@/api/order-api';
import QRCodeDefault from '@/assets/image/shop/qrcode-default.png';
import { mapState } from 'vuex';
import { LINKS } from '@/config/Constants';

export default {
    props: {
        type: {
            type: String,
            default: 'vip'
        },
        goods: {
            type: Object,
            default: {
                goodsId: -1,
                goodsName: '',
                price: '0',
                currency: '￥',
                originalPrice: '0'
            }
        }
    },
    data() {
        return {
            channel: 'alipay',
            payCodeUrl: QRCodeDefault,
            tradeStatus: -1,
            qrCodeLoading: false,
            orderNumber: '',
            statusTimer: null
        };
    },
    beforeDestroy() {
        this.canclePolling();
        this.orderNumber = '';
    },
    methods: {
        /**
         * 打开会员服务协议
         * @returns {void}
         */
        linkToMembership() {
            window.open(LINKS.membership);
        },
        /**
         * 生成支付二维码
         * @param {string} type - 生成二维码的类型 init: 初始化支付订单 update: 更新二维码
         */
        async generateQrCode(type = 'init') {
            if (!this.status) return;
            try {
                this.qrCodeLoading = true;
                const data = {
                    source: 0, // 0: web
                    goodsId: this.goods.goodsId, // 根据实际情况传入商品ID
                    payAgent: this.channel
                };
                if (type === 'update') {
                    data.orderNumber = this.orderNumber; // 传入ordrNumber只更新二维码
                }
                const res = await fetchPayCodeUrl(data);
                const { payUrl, orderNumber } = res.data;
                // 这里先清空url的原因在于
                // iframe中的url在切换时也会被浏览器记录
                // 导致回退时会先回退iframe再回退父页面
                // 每次切换时先将url置空，并通过v-if控制iframe是否销毁
                // 以避免iframe url被记录到浏览器中
                this.payCodeUrl = '';
                this.$nextTick(() => {
                    this.payCodeUrl = payUrl;
                });
                this.orderNumber = orderNumber;
                // 在轮询到结果前先将状态置为未扫码
                this.tradeStatus = -1;
                if (!this.statusTimer) this.pollingStatus();
            } catch (error) {
                console.log('Error while generating QR code:', error);
            }
            this.qrCodeLoading = false;
        },
        /**
         * 轮询订单状态
         */
        async pollingStatus() {
            this.fetchOrderStatus();
            this.statusTimer = setInterval(() => {
                this.fetchOrderStatus();
            }, 5000);
        },
        /**
         * 获取订单状态
         */
        async fetchOrderStatus() {
            try {
                const res = await fetchOrderStatus({
                    orderNumber: this.orderNumber,
                    payAgent: this.channel
                });
                const { tradeStatus } = res.data;
                if (tradeStatus === undefined)
                    throw new Error('未拿到订单状态');
                /**
                 * tradeStatus
                 * 0: 扫码成功待支付
                 * 1: 支付完成
                 * 2: 交易结束
                 * 3: 交易关闭（交易超时）
                 * -1: 交易不存在（未扫码）
                 */
                this.tradeStatus = tradeStatus;
                switch (tradeStatus) {
                    case 1:
                    case 2:
                        const { duration } = this.goods;
                        this.canclePolling();
                        this.$emit('success', this.type, duration);
                        break;
                    case 3:
                        this.canclePolling();
                        break;
                    default:
                        break;
                }
            } catch (error) {
                console.log('获取订单状态失败：', error);
            }
        },
        /**
         * 取消订单轮询
         */
        canclePolling() {
            if (this.statusTimer) {
                clearInterval(this.statusTimer);
                this.statusTimer = null;
            }
        },
        /**
         * 刷新二维码
         */
        handleRefresh() {
            if (this.maskTip === this.$t('订单已超时点击刷新')) {
                this.generateQrCode('update');
            }
        },
        /**
         * 支付渠道切换
         * @param {string} tab - 渠道
         */
        payTabChange(tab) {
            if (this.channel === tab) return;
            this.channel = tab;
            this.generateQrCode('update');
        }
    },
    computed: {
        ...mapState({
            status: state => state.status
        }),
        maskTip() {
            if (!this.status) {
                return this.$t('登陆后查看');
            }
            if (this.tradeStatus === 3) {
                return this.$t('订单已超时点击刷新');
            }
        }
    },
    watch: {
        goods: {
            handler() {
                this.generateQrCode('init');
            },
            deep: true,
            immediate: true
        }
    }
};
</script>

<style scoped lang="less">
.pay-code-box {
    width: 100%;
    height: 100%;

    .pay-tabs {
        display: flex;
        height: 32px;

        .wxpay,
        .alipay {
            padding: 0 10px;
            height: 100%;
            line-height: 32px;
            border-radius: 6px;
            border: 1px solid #e3e3e3;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.65);
            cursor: pointer;
        }

        .alipay {
            margin-right: 10px;
        }

        .pay-tab.vip {
            background: linear-gradient(137deg, #ffe8ac 0%, #fef5de 100%);
            border: none;
        }
        .pay-tab.duration {
            background-color: rgba(24, 118, 255, 0.1);
            border: 1px solid #1876ff;
        }
    }

    .pay-content {
        display: flex;
        margin-top: 12px;
        padding: 14px;
        height: calc(100% - 44px);
        background-color: #f8f8f8;
        border-radius: 10px;

        .qr-code-warpper {
            position: relative;
            height: 140px;

            .qr-code-mask {
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                background-color: rgba(45, 45, 45, 0.7);
                backdrop-filter: blur(4px);
                line-height: 140px;
                color: #ffffff;
                font-size: 14px;
                font-weight: 500;
                cursor: pointer;
            }
        }

        .qr-code-status {
            position: relative;
            flex: 1;
            margin-left: 16px;
            text-align: left;

            .agreement {
                font-size: 13px;
                color: rgba(0, 0, 0, 0.45);
            }

            .trade-tip {
                margin-top: 10px;
                font-size: 14px;
                color: #272727;
            }

            .trade-tip.red {
                color: #e02020;
            }

            .price {
                position: absolute;
                right: 0;
                bottom: 0;
                font-size: 13px;
                color: rgba(0, 0, 0, 0.45);
            }
        }
    }
}
</style>
