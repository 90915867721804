<template>
    <div :class="['member-privilege', $i18n.locale === 'en' && 'en']">
        <div
            class="privilege-box"
            v-for="(item, index) in privilegeList"
            :key="index"
        >
            <img :src="item.url" alt="权益" />
            <div :class="['privilege-name', index === 3 && 'ai']">
                {{ item.name }}
            </div>
        </div>
    </div>
</template>

<script>
import Privilege1 from '@/assets/image/shop/privilege-1.png';
import Privilege2 from '@/assets/image/shop/privilege-2.png';
import Privilege3 from '@/assets/image/shop/privilege-3.png';
import Privilege4 from '@/assets/image/shop/privilege-4.png';
import Privilege5 from '@/assets/image/shop/privilege-5.png';
import Privilege6 from '@/assets/image/shop/privilege-6.png';
import Privilege7 from '@/assets/image/shop/privilege-7.png';
import Privilege8 from '@/assets/image/shop/privilege-8.png';

export default {
    data() {
        return {};
    },
    computed: {
        privilegeList() {
            return [
                {
                    name: '每月20小时转写',
                    url: Privilege1
                },
                {
                    name: '极速转写通道',
                    url: Privilege2
                },
                {
                    name: '超长实时转写',
                    url: Privilege3
                },
                {
                    name: 'AI智能摘要',
                    url: Privilege4
                },
                {
                    name: '超多语种互译',
                    url: Privilege5
                },
                {
                    name: '签到双倍奖励',
                    url: Privilege6
                },
                {
                    name: '海量存储空间',
                    url: Privilege7
                },
                {
                    name: 'Stay tuned',
                    url: Privilege8
                }
            ];
        }
    }
};
</script>

<style scoped lang="less">
.member-privilege {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: space-between;
    padding: 16px 12px;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    border: 2px solid #ffeccb;

    .privilege-box {
        width: calc(100% / 4);

        img {
            width: 40px;
        }

        .privilege-name {
            margin: 0 auto;
            width: 60px;
            font-size: 13px;
            color: #272727;
            letter-spacing: 1px;
        }

        .privilege-name.ai {
            width: 55px;
        }
    }
}

.member-privilege.en .privilege-box .privilege-name {
    width: fit-content !important;
}
</style>
