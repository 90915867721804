<template>
    <div class="redeem-item">
        <div class="store-item">
            <div class="product-wrapper">
                <img
                    class="product-bg"
                    src="../../assets/image/card/card_background_blue.png"
                    alt="背景"
                />
                <div class="duration-num">{{ durationShow }}</div>
                <div class="duration-unit">{{ durationNameShow }}</div>
            </div>
            <div class="product-detail">
                <div class="product-title">{{ goods.goodsName }}</div>
                <div class="product-introduce">{{ goods.goodsIntroduce }}</div>
                <div class="product-expiry-date">
                    {{ $t('有效期') }}：{{ expiryDate }}
                </div>
            </div>
        </div>
        <div class="count">
            <div class="total-wrapper">
                <div class="total">
                    <img
                        class="yuelu-coin"
                        src="../../assets/image/shop/yuelu-coin.png"
                        alt="悦豆"
                    />
                    <span class="total-num">{{ totalCoins }}</span>
                </div>
                <div v-if="goods.discount" class="product-original-price">
                    <span>{{ $t('原价') }}：</span>
                    <span>{{ goods.originalCoin * count }}</span>
                </div>
            </div>
            <el-input-number
                v-model="count"
                :min="1"
                :max="maxNum"
                :step="1"
                size="mini"
                step-strictly
                @change="countChange"
                @blur="countBlur"
            ></el-input-number>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

const HOURBYSECOND = 3600;
const MINUTEBYSECOND = 60;
const DAYBYSECOND = HOURBYSECOND * 24;
const MONTHBYSECOND = DAYBYSECOND * 30;

export default {
    props: {
        goods: {
            type: Object,
            default: {
                discount: 0,
                duration: 0,
                effectiveTime: 0,
                exchangeCoin: 0,
                goodsId: 1,
                goodsIntroduce: null,
                goodsName: '半小时转写时长卡',
                goodsPicPath: null,
                originalCoin: 0
            }
        },
        neededCoin: {
            type: Number,
            default: 0
        },
        showRedeem: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            count: 1
        };
    },
    methods: {
        countChange(curVal, oldVal) {
            if (curVal < oldVal) {
                // 埋点
                window.weblog.report({
                    id: 'voiceclub_idyun_webShop_exchangeConfirm_decrease',
                    action: 'click'
                });
            } else if (curVal > oldVal) {
                // 埋点
                window.weblog.report({
                    id: 'voiceclub_idyun_webShop_exchangeConfirm_increase',
                    action: 'click'
                });
            }
        },
        countBlur() {
            // 埋点
            window.weblog.report({
                id: 'voiceclub_idyun_webShop_exchangeConfirm_modify',
                action: 'click'
            });
        }
    },
    computed: {
        ...mapState({
            allCoinNumber: state => state.allCoinNumber
        }),
        totalCoins() {
            return this.neededCoin * this.count;
        },
        maxNum() {
            return Math.floor(this.allCoinNumber / this.neededCoin);
        },
        durationShow() {
            const { duration } = this.goods;
            const hours = duration / HOURBYSECOND;
            if (hours >= 1) {
                return hours;
            } else {
                return duration / MINUTEBYSECOND;
            }
        },
        durationNameShow() {
            const { duration } = this.goods;
            const hours = duration / HOURBYSECOND;
            return hours >= 1 ? this.$t('小时') : this.$t('分钟');
        },
        expiryDate() {
            const { effectiveTime } = this.goods;
            const months = effectiveTime / MONTHBYSECOND;
            if (months < 1 || months % 1 !== 0) {
                return effectiveTime / DAYBYSECOND + this.$t('天');
            } else {
                return months + this.$t('个月');
            }
        }
    },
    watch: {
        count(newVal) {
            if (isNaN(newVal)) {
                this.count = 1;
            }
        }
    }
};
</script>

<style scoped lang="less">
.redeem-item {
    .store-item {
        position: relative;
        display: flex;
        padding: 14px;
        background-color: #ffffff;
        border-radius: 8px 8px 0 0;
        border-bottom: 1px solid #f5f5f5;
        .product-wrapper {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            aspect-ratio: 96 / 110;
            flex-shrink: 0;
            width: 33.1%;
            color: #ffffff;
            .product-bg {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
            }
            .duration-num {
                font-weight: 600;
                font-size: 40px;
                z-index: 999;
            }
            .duration-unit {
                z-index: 999;
            }
        }
        .product-detail {
            position: relative;
            display: flex;
            flex-direction: column;
            flex: 1;
            padding: 0 12px;
            text-align: left;
            overflow: hidden;
            div {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            .product-title {
                margin: 10px 0;
                font-weight: 600;
                font-size: 18px;
                color: #243041;
            }
            .product-introduce,
            .product-expiry-date {
                margin-bottom: 14px;
                color: #4f5967;
                white-space: normal;
            }
        }
    }
    .count {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px;
        background-color: #ffffff;
        border-radius: 0 0 8px 8px;
        .total-wrapper {
            display: flex;
            align-items: center;
            .total {
                display: flex;
                align-items: center;
                margin-right: 12px;
                .yuelu-coin {
                    margin-right: 6px;
                    width: 18px;
                    height: 18px;
                }
                .total-num {
                    margin-left: 4px;
                    line-height: 28px;
                    font-weight: 600;
                    font-size: 14px;
                    color: #fa6400;
                }
            }
            .product-original-price {
                font-weight: 400;
                font-size: 14px;
                color: #b9bdc3;
                text-decoration: line-through;
            }
        }
        .el-input-number {
            width: 100px;
            /deep/ input {
                border: none;
                color: #243041;
                font-weight: 600;
            }
            /deep/ .el-input-number__decrease,
            /deep/ .el-input-number__increase {
                background: #f2f5fa;
                border: none;
                font-weight: 900;
            }
        }
    }
}
</style>
