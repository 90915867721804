var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.cashierVis)?_c('el-dialog',{class:[
        'cashier-dialog',
        _vm.activeTab === 0 && 'vip',
        _vm.showTab && 'showTab'
    ],attrs:{"visible":_vm.cashierVis,"close-on-click-modal":false,"title":_vm.title,"before-close":_vm.beforeClose},on:{"update:visible":function($event){_vm.cashierVis=$event}}},[(_vm.showTab)?_c('div',{staticClass:"purchase-tabs"},[_c('div',{class:[
                'purchase-vip',
                'purchase-tab',
                _vm.activeTab === 0 && 'active'
            ],on:{"click":function($event){_vm.activeTab = 0}}},[_c('img',{staticClass:"purchase-tab-img",attrs:{"src":require("../../assets/image/shop/purchase-tab-bg.png"),"alt":"购买会员"}}),_c('span',[_vm._v("购买会员")])]),_c('div',{class:[
                'purchase-duration',
                'purchase-tab',
                _vm.activeTab === 1 && 'active',
                'reverse'
            ],on:{"click":function($event){_vm.activeTab = 1}}},[_c('img',{staticClass:"purchase-tab-img",attrs:{"src":require("../../assets/image/shop/purchase-tab-bg.png"),"alt":"购买时长卡"}}),_c('span',[_vm._v("购买时长卡")])])]):_vm._e(),(_vm.activeTab === 0)?_c('purchase-vip',{style:({ height: _vm.showTab ? 'calc(100% - 44px)' : '100%' }),on:{"success":_vm.handleSuccess}}):_c('purchase-duration-card',{style:({ height: _vm.showTab ? 'calc(100% - 44px)' : '100%' }),attrs:{"id":_vm.id},on:{"success":_vm.handleSuccess}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }