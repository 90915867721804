<template>
    <el-input
        class="login-input"
        :value="value"
        placeholder="Enter your Email"
        :maxlength="40"
        @input="updateValue"
    >
        <div slot="prefix" class="prefix-icon-container">
            <img
                class="prefix-icon"
                slot="prefix"
                :src="emailUrl"
                alt="email"
            />
        </div>
    </el-input>
</template>

<script>
import EmailIcon from '@/assets/image/auth/email-icon.png';
import EmailActiveIcon from '@/assets/image/auth/email-active-icon.png';

export default {
    props: {
        value: {
            type: String | Number,
            required: true,
        },
    },
    data() {
        return {
            EmailIcon,
            EmailActiveIcon,
        };
    },
    methods: {
        updateValue(newVal) {
            this.$emit('input', newVal.replace(/\s+/g, ''));
        },
    },
    computed: {
        emailUrl() {
            return this.value.length > 0 ? EmailActiveIcon : EmailIcon;
        },
    },
};
</script>

<style scoped lang="less">
.login-input {
    .prefix-icon-container {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        /deep/ .prefix-icon {
            width: 100%;
            height: 100%;
            vertical-align: top;
        }
    }

    .prefix-icon-container {
        left: 16px;
        width: 20px;
        height: 20px;
    }

    /deep/ .el-input__inner {
        padding: 2px 46px 0; // 不清除什么原因导致icon和文字在视觉上不是垂直居中的添加pt手动居中
        height: 48px;
        border-radius: 12px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        font-weight: 500;
        font-size: 16px;
        color: #000000;
    }

    /deep/ .el-input__inner::placeholder {
        font-weight: 500;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.3);
    }

    /deep/ .el-input__inner:focus {
        border-color: #1876ff;
    }

    /deep/ .el-input__prefix {
        left: 0;
    }
}
</style>
