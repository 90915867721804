import { getFeedbackCategory } from '../../api/feedback-api';
const state = {
    feedbackCategory: []
};

const getters = {};

const mutations = {
    feedbackContent(state, data) {
        state.feedbackCategory = data;
    }
};

const actions = {
    getFbCategory(context) {
        getFeedbackCategory().then(res => {
            if (res.status_code === 1) {
                const formatFeedback = [];
                const feedbackData = res.data;
                feedbackData.forEach(item => {
                    if(item.level === 0) {
                        const {id, name} = item;
                        formatFeedback.push({id, name, children: []})
                    } else {
                        const {id, name, parentId} = item;
                        formatFeedback.find(parent => parent.id === parentId).children.push({id, name})
                    }
                });
                context.commit('feedbackContent', formatFeedback);
            } else {
                alert('获取意见反馈失败');
            }
        });
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
