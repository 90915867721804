import { STORAGE_KEYS, statusMap } from '../../config/Constants';
import { secondsToStr } from '../../utils/TimeUtils';
import {
    getAllAudios,
    getCurAudioList,
    getUntransferAudio,
    batchMoveAudio
} from '../../api/audioListen-api';
import { startTransferBatch } from '../../api/transfer-api';
import { deleteRecord, batchDeleteRecord } from '../../api/common-api';
import { updateRecord } from '../../api/voice-detail-api';
const state = {
    audioData: {}
};

const getters = {};

const mutations = {
    myAudio(state, data) {
        if (data.progress) {
            let audioData = data.data.data;
            for (let i = 0; i < audioData.length; i++) {
                if (audioData[i].status == 'transfering') {
                    state.audioData.data[i].progress = audioData[i].progress;
                    return;
                }
            }
        }
        state.audioData = data.data;
    }
};

const actions = {
    getMyUnTransedAudio(context, query) {
        const { success, error, params } = query;
        getUntransferAudio(params)
            .then(res => {
                let response = res.data;
                let data = [];
                response.forEach(item => {
                    data.push({
                        status: item.fileStatus,
                        fileName: item.fileName,
                        show: false,
                        timeLen: item.timeLen,
                        timeStr: secondsToStr(item.timeLen),
                        fileId: item.fileId
                    });
                });
                success(data);
            })
            .catch(error);
    },
    addAudioRecord(context, query) {
        let { success, error, params, time } = query;
        let {
            rootState: { userId }
        } = context;
        let filename = params.fileName.substr(
            0,
            params.fileName.lastIndexOf('.')
        );
        let format = params.fileName.substr(
            params.fileName.lastIndexOf('.') + 1
        );
        // 用户在线登录状态下添加数据
        let recordParams = {
            userId: userId,
            fileName: filename,
            timeLen: time,
            audioPath: params.audioPath,
            fileSize: params.fileSize,
            sampleRate: params.sampleRate,
            format: format,
            source: 0
        };
        // addRecord(recordParams).then(success).catch(error)
        success(recordParams);
    },
    deleteAudio(context, query) {
        let { success, error, params } = query;
        deleteRecord(params)
            .then(success)
            .catch(error);
    },
    batchDelete(context, data) {
        const { params, error, success } = data;
        // 用户登录状态删除数据
        batchDeleteRecord(params)
            .then(success)
            .catch(error);
    },
    renameRecord(context, data) {
        const { params, error, success } = data;
        updateRecord(params)
            .then(success)
            .catch(error);
    },
    // 音频迁移
    transforAudio(context, query) {
        let { params, error, success } = query;
        batchMoveAudio(params)
            .then(success)
            .catch(error);
    },
    // 音频转文字
    audioToText(context, query) {
        let { success, error, params } = query;
        startTransferBatch(params)
            .then(success)
            .catch(error);
    },
    // 批量音频转文字
    batchAudioToText(context, query) {
        let { success, error, params } = query;
        startTransferBatch(params)
            .then(success)
            .catch(error);
    },

    //获取全部的音频
    getAllAudios(context, query) {
        let { success, error, params, progress } = query;
        let data = {};
        getAllAudios(params)
            .then(res => {
                const response = res.data;
                data.total = response.total;
                data.data =
                    response.records.map(item => ({
                        ...item,
                        status: statusMap[item.fileStatus],
                        timeStr: secondsToStr(item.timeLen),
                        uptime: dateFormat(item.updateTime)
                    })) || [];
                context.commit('myAudio', {
                    data: data,
                    progress: progress
                });
                success();
            })
            .catch(() => {
                error();
            });
    }
};

function dateFormat(data) {
    let date = new Date(data * 1000);
    let format = 'yyyy-MM-dd hh:mm:ss';
    let o = {
        'M+': date.getMonth() + 1,
        'd+': date.getDate(),
        'h+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds(),
        'q+': Math.floor((date.getMonth() + 3) / 3),
        S: date.getMilliseconds()
    };
    if (/(y+)/.test(format))
        format = format.replace(
            RegExp.$1,
            (date.getFullYear() + '').substr(4 - RegExp.$1.length)
        );
    for (var k in o) {
        if (new RegExp('(' + k + ')').test(format))
            format = format.replace(
                RegExp.$1,
                RegExp.$1.length == 1
                    ? o[k]
                    : ('00' + o[k]).substr(('' + o[k]).length)
            );
    }
    return format;
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
