<template>
    <el-input
        class="verify-code-input"
        :value="value"
        placeholder="Enter Verificaction Code"
        :maxlength="6"
        @input="updateValue"
    >
    </el-input>
</template>

<script>
export default {
    props: {
        value: {
            type: String | Number,
            required: true,
        },
    },
    data() {
        return {};
    },
    methods: {
        updateValue(newVal) {
            this.$emit('input', newVal.replace(/[^\d]/g, ''));
        },
    },
};
</script>

<style scoped lang="less">
.verify-code-input {
    /deep/ .el-input__inner {
        padding: 0 16px;
        height: 48px;
        border-radius: 12px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        font-weight: 500;
        font-size: 16px;
        color: #000000;
    }

    /deep/ .el-input__inner::placeholder {
        font-weight: 500;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.3);
    }

    /deep/ .el-input__inner:focus {
        border-color: #1876ff;
    }
}
</style>
