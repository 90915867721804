<template>
    <div class="activity" v-if="list.length > 0">
        <el-carousel :arrow="list.length < 2 ? 'never' : 'hover'">
            <el-carousel-item v-for="activity in list" :key="activity.id">
                <img
                    :src="activity.image"
                    alt="活动"
                    @click="openLink(activity)"
                />
            </el-carousel-item>
        </el-carousel>
    </div>
</template>

<script>
export default {
    props: {
        list: {
            type: Array,
            default: [
                {
                    id: '',
                    image: ''
                }
            ]
        }
    },
    methods: {
        openLink({ link }) {
            window.open(link);

            // 埋点
            window.weblog.report({
                id: 'voiceclub_idyun_webZSC_dailyCheckIn_banner',
                action: 'click'
            });
        }
    }
};
</script>

<style scoped lang="less">
.activity {
    width: 100%;
    aspect-ratio: 335 / 75;
    .el-carousel {
        height: 100%;
        /deep/ .el-carousel__container {
            height: 100%;
        }
    }
    img {
        width: calc(100% - 1px);
        height: 100%;
        cursor: pointer;
        border-radius: 9px;
    }
}
</style>
