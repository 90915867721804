<template>
    <el-input
        class="login-input"
        :value="value"
        :type="passwordType"
        :placeholder="placeholder"
        :maxlength="20"
        @input="updateValue"
    >
        <div slot="prefix" class="prefix-icon-container">
            <img
                class="prefix-icon"
                slot="prefix"
                :src="passwordUrl"
                alt="password"
            />
        </div>
        <div slot="suffix" class="suffix-icon-container">
            <img
                class="suffix-icon"
                slot="suffix"
                :src="
                    passwordType === 'password'
                        ? PasswordHiddenIcon
                        : PasswordVisibleIcon
                "
                alt="hidden"
                style="cursor: pointer"
                @click="handlePassword"
            />
        </div>
    </el-input>
</template>

<script>
import PasswordIcon from '@/assets/image/auth/password-icon.png';
import PasswordActiveIcon from '@/assets/image/auth/password-active-icon.png';
import PasswordHiddenIcon from '@/assets/image/auth/password-hidden-icon.png';
import PasswordVisibleIcon from '@/assets/image/auth/password-visible-icon.png';

export default {
    props: {
        value: {
            type: String | Number,
            required: true,
        },
        placeholder: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            passwordType: 'password',
            PasswordHiddenIcon,
            PasswordVisibleIcon,
        };
    },
    methods: {
        updateValue(newVal) {
            // 替换空格
            this.$emit('input', newVal.replace(/\s+/g, ''));
        },
        handlePassword() {
            if (this.passwordType === 'password') {
                this.passwordType = 'text';
            } else {
                this.passwordType = 'password';
            }
        },
    },
    computed: {
        passwordUrl() {
            return this.value.length > 0 ? PasswordActiveIcon : PasswordIcon;
        },
    },
};
</script>

<style scoped lang="less">
.login-input {
    .prefix-icon-container,
    .suffix-icon-container {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        /deep/ .prefix-icon,
        /deep/ .suffix-icon {
            width: 100%;
            height: 100%;
            vertical-align: top;
        }
    }

    .prefix-icon-container {
        left: 16px;
        width: 20px;
        height: 20px;
    }

    .suffix-icon-container {
        right: 16px;
        width: 16px;
        height: 16px;
    }

    /deep/ .el-input__inner {
        padding: 2px 46px 0; // 不清除什么原因导致icon和文字在视觉上不是垂直居中的添加pt手动居中
        height: 48px;
        border-radius: 12px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        font-weight: 500;
        font-size: 16px;
        color: #000000;
    }

    /deep/ .el-input__inner::placeholder {
        font-weight: 500;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.3);
    }

    /deep/ .el-input__inner:focus {
        border-color: #1876ff;
    }

    /deep/ .el-input__prefix {
        left: 0;
    }
}
</style>
