import { Get, Post } from '../utils/HttpUtils';

/** 获取订单列表
 *  pageSize
 *  page
 *  type
 */
export const getOrderList = params => Get('/api/v1/order/list', params);

/** 导出Word */
export const downloadWord = params =>
    Get('/api/v1/order/subtitle_file', params, {
        responseType: 'blob'
    });

/** 获取结算页面信息 */
export const getSettlementInfo = params => Get('/order/get_checkout', params);

/**
 * 获取临时订单详情
 * @param {string} orderNumber - 临时订单编号
 * @returns {Promise<object>} 订单详情
 */

export const getOrderDetail = params => Get('/order/checkout', params);

/**
 * 获取商品列表
 * @param {number} type - 商品类型
 * @returns {Promise<object>} 商品列表
 */

export const fetchGoods = params => Get('/api/v1/store/pay_goods_list', params);

/**
 * 生成二维码订单
 * @param {number} source - 来源
 * @param {number} goodsId - 商品id
 * @returns {Promise<object>} 二维码链接
 */

export const fetchPayCodeUrl = data => {
    return Post('/api/v1/pay/execute_payment', data);
};

/**
 * 获取订单状态
 * @param {string} tradeNumber - 支付凭证（该参数前端没有）
 * @param {string} orderNumber - 订单编号（和支付凭证二选一可查询状态，对于前端来说只使用orderNumber）
 * @returns {Promise<object>} 订单状态
 */

export const fetchOrderStatus = data =>
    Post('/api/v1/pay/check_pay_result', data);

/**
 * 获取订单列表及详情
 * @param {number} page - 当前页
 * @param {number} pageSize - 每页条数
 * @returns {Promise<object>} 订单列表及详情
 */

export const fetchOrderList = params =>
    Get('/api/v1/store/pay_order_list', params);
