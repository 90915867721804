<template>
    <div class="task-item">
        <div class="task-wrapper">
            <div class="task-title">
                <span
                    >{{ data.challengeName }} ({{ data.progressFinish }}/{{
                        data.progressAmount
                    }})</span
                >
                <img
                    v-if="data.rewardType === 0"
                    class="coin-icon"
                    src="../../assets/image/shop/yuelu-coin.png"
                    alt="奖励"
                />
                <div v-else class="time-card">
                    <img
                        src="../../assets/image/shop/time-card-bg.png"
                        alt="时长卡"
                    />
                    <span>{{ timeFormat(data.rewardNumber) }}</span>
                </div>
                <span v-if="data.rewardType === 0" style="color: #fa6400"
                    >+{{ data.rewardNumber }}</span
                >
                <span v-else style="color: #878889">x{{ 1 }}</span>
            </div>
            <div class="task-desc">{{ data.challengeDesc }}</div>
        </div>
        <div :class="['task-btn', btnClass]" @click="receiveReward">
            {{ btnLabel }}
        </div>
    </div>
</template>

<script>
import { getTaskReward } from '@/api/signIn-api';
import createRewardBox from '@/extend/rewardBox';

const HOURBYSECOND = 3600;
const MINUTEBYSECOND = 60;

export default {
    props: {
        data: {
            type: Object,
            default: {
                challengeDesc: '',
                challengeId: 1,
                challengeName: '',
                challengeSortType: null,
                challengeType: 0,
                progressAmount: 1,
                progressFinish: 0,
                progressStatus: 0,
                rewardNumber: 38,
                rewardType: 0
            }
        }
    },
    data() {
        return {
            rewardBox: null
        };
    },
    methods: {
        timeFormat(num) {
            const hours = num / HOURBYSECOND;
            if (hours >= 1) {
                return `${hours}${this.$t('小时')}`;
            } else {
                return `${num / MINUTEBYSECOND}${this.$t('分钟')}`;
            }
        },
        async receiveReward() {
            if (this.data.progressStatus !== 1) return;
            try {
                const res = await getTaskReward({
                    challengeId: this.data.challengeId
                });
                const result = res.data.resultList;
                if (result.length <= 0) {
                    this.$message.error(this.$t('暂无可领取奖励'));
                } else {
                    const { rewardType, rewardNumber } = result[0];
                    const reward = {
                        type: rewardType,
                        num: rewardNumber,
                        amount: 1
                    };
                    this.rewardBox = createRewardBox(
                        document.querySelector('#app'),
                        reward
                    );
                    this.rewardBox.show();
                }
            } catch (error) {
                console.log(error);
            }
            this.$emit('refreshList');
        }
    },
    computed: {
        btnClass() {
            return this.data.progressStatus === 0
                ? 'unfinish'
                : this.data.progressStatus === 1
                ? 'to-receive'
                : 'finished';
        },
        btnLabel() {
            return this.data.progressStatus === 0
                ? this.$t('未完成')
                : this.data.progressStatus === 1
                ? this.$t('领奖励')
                : 'Completed';
        }
    }
};
</script>

<style scoped lang="less">
.task-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 18px;
    width: 100%;
    text-align: left;
    background-color: #f7f8fa;
    border-radius: 12px;
    .task-title {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        font-weight: 600;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.85);
        .time-card {
            position: relative;
            margin: 0 8px 0 12px;
            width: 45px;
            height: 30px;
            img {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
            }
            span {
                display: inline-block;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%) scale(0.8);
                text-align: center;
                width: 100%;
                font-size: 12px;
                color: #ffffff;
            }
        }
        .coin-icon {
            margin-left: 12px;
            margin-right: 8px;
            width: 20px;
            vertical-align: middle;
        }
    }
    .task-desc {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.45);
    }
    .task-btn {
        padding: 0 14px;
        height: 32px;
        line-height: 32px;
        border-radius: 16px;
        font-weight: 500;
        color: #ffffff;
        background-color: #bbbfc3;
    }
    .task-btn.to-receive {
        cursor: pointer;
        background: linear-gradient(316deg, #ff5f29 0%, #ff5858 100%);
    }
    .task-btn.unfinish {
        background-color: #bbbfc3;
    }
    .task-btn.finished {
        background-color: #d7dbe3;
    }
    .task-btn.to-receive:hover {
        opacity: 0.8;
    }
}
</style>
