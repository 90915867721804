<template>
    <div :class="['check-in-item', setClass]" @click="checkIn">
        <!-- <img class="reward-img" :src="rewardPicPath" alt="奖励" /> -->
        <img
            v-if="rewardType === 0"
            class="reward-img"
            src="../../assets/image/shop/yuelu-coin.png"
            alt="奖励"
        />
        <div v-else class="time-card">
            <img src="../../assets/image/shop/time-card-bg.png" alt="时长卡" />
            <span>{{ timeFormat(rewardNum) }}</span>
        </div>
        <div v-if="rewardType === 0" class="reward-count">{{ rewardNum }}</div>
        <div class="index">{{ day }}</div>
        <img
            v-if="status === 0"
            class="checked-icon"
            src="../../assets/image/shop/checked-icon.png"
            alt="已签"
        />
    </div>
</template>

<script>
const HOURBYSECOND = 3600;
const MINUTEBYSECOND = 60;

export default {
    props: {
        /* 
            0 已签
            1 今日待签
            2 以后待签
         */
        status: {
            type: Number,
            default: 0
        },
        day: {
            type: Number,
            default: 1
        },
        rewardNum: {
            type: Number,
            default: 0
        },
        rewardType: {
            type: Number,
            default: 0
        },
        rewardPicPath: {
            type: String,
            default: ''
        }
    },
    methods: {
        timeFormat(num) {
            const hours = num / HOURBYSECOND;
            if (hours >= 1) {
                return `${hours}${this.$t('小时')}`;
            } else {
                return `${num / MINUTEBYSECOND}${this.$t('分钟')}`;
            }
        },
        checkIn() {
            if (this.status === 1) this.$emit('checkIn');
        }
    },
    computed: {
        setClass() {
            if (this.status === 0) {
                return 'checked';
            } else if (this.status === 1) {
                return 'to-check';
            } else {
                return '';
            }
        }
    }
};
</script>

<style scoped lang="less">
.check-in-item {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    aspect-ratio: 41 / 36;
    width: 100%;
    overflow: hidden;
    background-color: #f4fbed;
    border-radius: 8px;
    .reward-img {
        margin-top: 6px;
        width: 40%;
    }
    .time-card {
        position: relative;
        margin: 0 8px 0 12px;
        width: 70%;
        aspect-ratio: 1.5 / 1;
        img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }
        span {
            display: inline-block;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%) scale(0.8);
            text-align: center;
            width: 100%;
            font-size: 12px;
            color: #ffffff;
        }
    }
    .reward-count {
        font-weight: 600;
        font-size: 12px;
        color: #fa6400;
    }
    .index {
        position: absolute;
        left: 6px;
        top: 4px;
        font-weight: 900;
        font-size: 12px;
        color: #c8d9b7;
    }
    .checked-icon {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 38.78%;
    }
}
.check-in-item.checked {
    background-color: rgba(255, 255, 255, 0.7);
    > *:not(.checked-icon) {
        opacity: 0.4;
    }
}
.check-in-item.to-check {
    background: linear-gradient(123deg, #ff914a 0%, #ff3d1b 100%);
    .index,
    .reward-count {
        color: #ffffff;
    }
    cursor: pointer;
}
.check-in-item.to-check:hover {
    opacity: 0.8;
}
</style>
