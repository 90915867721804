<template>
    <div>
        <el-dialog
            class="reward-dialog"
            ref="rewardDialog"
            v-if="rewardVis"
            :visible.sync="rewardVis"
            :close-on-click-modal="false"
            :before-close="beforeClose"
        >
            <!-- <div
                :class="['check-in-tag', activeTag === 0 && 'active']"
                @click="activeTag = 0"
            >
                {{ $t('每日签到') }}
            </div>

            <div
                :class="['activity-tag', activeTag === 1 && 'active']"
                @click="activeTag = 1"
            >
                {{ $t('任务挑战') }}
            </div> -->

            <!-- <div
                :class="['gift-tag', activeTag === 2 && 'active']"
                @click="activeTag = 2"
            >
                {{ $t('礼包兑换') }}
            </div> -->

            <keep-alive>
                <check-in v-if="activeTag === 0"> </check-in>
            </keep-alive>

            <keep-alive>
                <task v-if="activeTag === 1"></task>
            </keep-alive>

            <gift-exchange v-if="activeTag === 2"></gift-exchange>

            <img
                v-if="activeTag === 0"
                class="checkin-bg"
                src="../../assets/image/shop/checkin-bg.png"
                alt="checkin-bg"
            />

            <img
                v-if="activeTag === 1"
                class="task-bg"
                src="../../assets/image/shop/task-bg.png"
                alt="task-bg"
            />

            <img
                v-if="activeTag === 2"
                class="gift-bg"
                src="../../assets/image/shop/gift-bg.png"
                alt="gift-bg"
            />
        </el-dialog>
    </div>
</template>

<script>
import CheckIn from './CheckIn.vue';
import Task from './Task.vue';
import GiftExchange from './GiftExchange.vue';

export default {
    props: {
        rewardVis: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        CheckIn,
        Task,
        GiftExchange,
    },
    data() {
        return {
            activeTag: 2,
        };
    },
    mounted() {
        this.$store.dispatch('getActivity');
    },
    methods: {
        beforeClose() {
            this.$emit('beforeClose');
        },
    },
};
</script>

<style scoped lang="less">
.reward-dialog /deep/ .el-dialog {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    aspect-ratio: 500 / 600;
    margin: 0 auto !important;
    width: 34.63%;
    min-width: 500px;
    border-radius: 20px;
    background-color: #ebfffa;
    .el-dialog__header {
        padding: 0;
    }
    .el-dialog__body {
        position: relative;
        padding: 28px;
        height: 100%;
        border-radius: 20px;
        z-index: -1;
        .check-in-tag,
        .activity-tag,
        .gift-tag {
            position: absolute;
            left: 0;
            transform: translateX(-100%);
            padding: 0 10px;
            width: 80px;
            height: 36px;
            line-height: 36px;
            background-color: #bdbec9;
            border-radius: 18px 0 0 18px;
            font-weight: 600;
            color: #707283;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .check-in-tag.active,
        .activity-tag.active,
        .gift-tag.active {
            background-color: #ffffff;
            color: rgba(0, 0, 0, 0.85);
        }
        .check-in-tag {
            top: 30px;
            cursor: pointer;
        }
        .activity-tag {
            top: 78px;
            cursor: pointer;
        }
        .gift-tag {
            top: 126px;
            cursor: pointer;
        }
        .store-wrapper::-webkit-scrollbar {
            width: 4px;
            background-color: #e5e5e5;
        }
        .store-wrapper::-webkit-scrollbar-thumb {
            background-color: #b7b7b7;
            border-radius: 4px;
        }
        .store-wrapper::-webkit-scrollbar-track {
            -webkit-box-shadow: 0 0 0px #808080 inset;
        }
    }

    .checkin-bg,
    .task-bg,
    .gift-bg {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 20px;
        z-index: -1;
    }
}
</style>
