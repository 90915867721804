/**
 * 秒转换为小时
 * @param seconds
 * @returns {number}
 */
export function hourFormat(seconds) {
    const num = new Number(seconds / 3600);
    return num;
}

export function hourFormat2(seconds) {
    var num = new Number(seconds / 3600);
    return num.toFixed(0);
}
/**
 * 秒转为string
 * @param {number} seconds - 秒
 * @returns {string} 格式化时间字符串
 */
export function secondsToStr(seconds) {
    if (seconds < 0) {
        return 'Invalid input';
    }

    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    return `${('0' + hours).slice(-2)}:${('0' + minutes).slice(-2)}:${(
        '0' + remainingSeconds
    ).slice(-2)}`;
}

export const dateFormat = data => {
    let date = new Date(data * 1000);
    let format = 'yyyy-MM-dd hh:mm:ss';
    let o = {
        'M+': date.getMonth() + 1,
        'd+': date.getDate(),
        'h+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds(),
        'q+': Math.floor((date.getMonth() + 3) / 3),
        S: date.getMilliseconds()
    };
    if (/(y+)/.test(format))
        format = format.replace(
            RegExp.$1,
            (date.getFullYear() + '').substr(4 - RegExp.$1.length)
        );
    for (var k in o) {
        if (new RegExp('(' + k + ')').test(format))
            format = format.replace(
                RegExp.$1,
                RegExp.$1.length === 1
                    ? o[k]
                    : ('00' + o[k]).substr(('' + o[k]).length)
            );
    }
    return format;
};
