import { Post, Get, Put } from '../utils/HttpUtils';

/* 
    获取用户签到信息
    params: {

    }
*/
export const fetchUserSignInfo = () => Post('api/v1/store/sign_info');

/* 
    签到
    params: {

    }
*/
export const sign = () => Post('api/v1/store/sign');

/* 
    获取商品列表
    params: {
        page: 页码,
        pageSize: 每页个数
    }
*/
export const fetchGoods = data => Post('api/v1/store/goods_list', data);

/* 
    兑换商品
    params: {
        coinNumber: 悦豆数额,
        goodsId: 商品id
    }
*/
export const purchaseGoods = data => Post('api/v1/store/purchase_goods', data);

/* 
    悦豆明细
    params: {
        page: 页码,
        pageSize: 每页个数
    }
*/
export const fetchCoinDetail = data => Post('api/v1/store/bean_detail', data);

/* 
    获取用户资产
    params: {

    }
*/
export const fetchUserAsset = () => Post('api/v1/store/get_asset');

/* 
    获取活动
    params: {

    }
*/
export const fetchActivity = () => Get('banner/get_banner?source=0');

/* 
    获取任务挑战
    params: {

    }
*/
export const fetchTask = () => Get('api/v1/challenge/task_list');

/* 
    领取任务奖励
    params: {
        taskId: 任务id
    }
*/
export const getTaskReward = data => Post('api/v1/challenge/receive_reward', data);

/* 
    领取兑换码奖励
    params: {
        packCode: 兑换码
    }
*/
export const giftCodeExchange = data =>
    Post('api/v1/gift_pack/gift_code_exchange', data);
