<template>
    <div class="login-box">
        <div class="login-title">Log in</div>
        <div class="email-container">
            <el-form :model="loginForm">
                <el-form-item prop="email">
                    <email-input v-model="loginForm.email"> </email-input>
                </el-form-item>
                <el-form-item prop="password">
                    <password-input
                        v-model="loginForm.password"
                        placeholder="Enter your Password"
                    >
                    </password-input>
                </el-form-item>
            </el-form>
        </div>
        <div class="forgot-password">
            <span @click="toForgotPwd">Forgot password?</span>
        </div>
        <submit-button
            :loading="loginLoading"
            :disabled="!inputNotNull"
            style="margin-top: 32px"
            @click="handleLogin"
            >Log in</submit-button
        >

        <div class="sign-up">
            Don't have an account?
            <span class="blue-text" @click="toSignup">Sign up!</span>
        </div>
    </div>
</template>

<script>
import EmailInput from '@/components/Auth/EmailInput';
import PasswordInput from '@/components/Auth/PasswordInput';
import SubmitButton from '@/components/Auth/SubmitButton';
import { emailLogin } from '@/api/login-api';
import { hashPassword } from '@/utils/passwordEncode';

export default {
    components: {
        EmailInput,
        PasswordInput,
        SubmitButton,
    },
    data() {
        return {
            loginForm: {
                email: '',
                password: '',
            },
            passwordType: 'password',
            loginLoading: false,
        };
    },
    methods: {
        async handleLogin() {
            try {
                this.loginLoading = true;
                const { email, password } = this.loginForm;
                const res = await emailLogin({
                    email,
                    password: hashPassword(password),
                });
                const { userId, token } = res.data;
                localStorage.setItem('userId', userId);
                localStorage.setItem('token', token);
                window.location.reload();
            } catch (error) {
                console.log(error);
            } finally {
                this.loginLoading = false;
            }
        },
        /**
         * 跳转到注册组件
         */
        toSignup() {
            this.$emit('authChange', 'signup');
        },
        /**
         * 跳转到重置密码组件
         */
        toForgotPwd() {
            this.$emit('authChange', 'forgot');
        },
    },
    computed: {
        inputNotNull() {
            return (
                this.loginForm.email.length > 0 &&
                this.loginForm.password.length > 0
            );
        },
    },
};
</script>

<style scoped lang="less">
.login-box {
    position: relative;
    padding-top: 48px;
    height: 100%;
    text-align: center;

    .login-title {
        font-weight: 600;
        font-size: 28px;
        color: #1d2129;
    }

    .email-container {
        margin-top: 82px;
    }

    .forgot-password {
        margin-top: 12px;
        text-align: right;
        font-weight: 500;
        color: #000000;

        span {
            cursor: pointer;
        }
    }

    .sign-up {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        font-weight: 500;
        font-size: 16px;
        color: #000000;

        .blue-text {
            color: #1876ff;
            cursor: pointer;
        }
    }
}
</style>
