import { updateRecord, getFileInfo, getTextInfo, saveContent } from '../../api/voice-detail-api'

const state = {
    transData: {
        title: "",
        titleBack: "",
        updateTime: "",
        content: [],
        show: true
    },
    // 说话人相关
    afterSeparationName: '',
    speakerList: '',
    beforeSeparationName: '',
    alternateStatus_: false,
    // 针对生成的section
    exchangeSpkName: {
        proName: '',
        newName: ''
    },
    isSeparation: true,
    isStampEnable: true,

    // mark
    markVal: ''
}

const getters = {
}

const mutations = {
    transData(state, data) {
        state.transData.title = data.fileName;
        state.transData.titleBack = data.fileName;
        state.transData.updateTime = dateFormat(data.updateTime);
    },
    saveFileName(state, title) {
        state.transData.title = title;
    },
    recoveryFileName(state) {
        state.transData.titleBack = state.transData.title;
    },
    transDataText(state, data) {
        if (!data) return // error first
        state.transData.show = true;
        try {
            state.transData.content = JSON.parse(data.asrResult);
        } catch (e) {
            console.error(e)
        }
    },
    // 说话人相关方法
    setAfterSeparationName(state, name) {
        state.afterSeparationName = name;
    },
    setSpeakerList(state, list) {
        state.speakerList = list;
    },
    setBeforeSeparationName(state, name) {
        state.beforeSeparationName = name;
    },
    setAlternateStatus(state, status) {
        state.alternateStatus_ = status;
    },
    setExchangeSpkName(state, nameObj) {
        state.exchangeSpkName = nameObj;
    },
    setIsSeparation(state, isSeparation) {
        state.isSeparation = isSeparation;
    },
    setIsStampEnable(state, isStampEnable) {
        state.isStampEnable = isStampEnable;
    },

    // mark
    setMarkVal(state, val) {
        state.markVal = val;
    }
}

const actions = {
    // 保存文本
    saveTransText(context, data) {
        let { params, error, success } = data;
        saveContent(params).then(success).catch(error)
    },
    // 保存标题
    saveTransData(context, data) {
        let { params, error, success } = data;
        updateRecord(params).then((res) => {
            success()
            context.commit('saveFileName', params.fileName);
        }).catch((e) => {
            error(e);
            context.commit('recoveryFileName');
        })
    },
    // 获取标题
    getTransData(context, data) {
        let { params, error, success } = data;
        getFileInfo(params).then((res) => {
            context.commit('transData', res.data);
            success(res.data);
        }).catch((e) => {
            error(e)
        })
    },
    // 获取文本
    getTransDataText(context, data) {
        let { params, error, success } = data;
        getTextInfo(params).then((res) => {
            context.commit("transDataText", res.data);
            if (success) success();
        }).catch(e => {
            error(e)
        })
    }
}
function dateFormat(updateDate) {
    let date = new Date(updateDate * 1000);
    let format = "yyyy-MM-dd hh:mm:ss";
    let o = {
        "M+": date.getMonth() + 1,
        "d+": date.getDate(),
        "h+": date.getHours(),
        "m+": date.getMinutes(),
        "s+": date.getSeconds(),
        "q+": Math.floor((date.getMonth() + 3) / 3),
        "S": date.getMilliseconds()
    };
    if (/(y+)/.test(format))
        format = format.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (var k in o) {
        if (new RegExp("(" + k + ")").test(format))
            format = format.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    }
    return format;

}
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
