<template>
    <div :class="['time-card', selected && 'selected']" @click="selectCard">
        <div class="duration-name" style="font-size: 14px">
            {{ timeCard.goodsName }}
        </div>
        <div class="duration-price">
            <span style="font-weight: 400">{{ timeCard.currency }}</span>
            <span style="font-weight: 900; font-size: 24px">{{
                timeCard.price | formatPrice()
            }}</span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            default: {
                goodsId: '',
                goodsName: '',
                price: '',
                currency: '￥'
            }
        },
        selected: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            timeCard: null
        };
    },
    created() {
        // 重命名
        this.timeCard = this.data;
    },
    methods: {
        selectCard() {
            if (this.selected) return; // 防止点击同一个卡片多次
            this.$emit('selected', this.timeCard);
        }
    }
};
</script>

<style scoped lang="less">
.time-card {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding: 0 12px;
    width: 100%;
    aspect-ratio: 138 / 120;
    background-color: #f5f5f5;
    border-radius: 12px;
    font-weight: 600;
    font-size: 16px;
    color: #555555;
}

.time-card.selected {
    background: rgba(24, 118, 255, 0.1);
    color: #1876ff;
}

.time-card.selected::after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    border: 2px solid #1876ff;
    border-radius: 12px;
}
</style>
