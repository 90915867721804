import { Get, Post } from '../utils/HttpUtils';

/**
 * 邮箱登录
 * @param {string} email  - 邮箱
 * @param {string} password  - 密码
 * @returns {Promise<object>} - 服务端返回的数据
 */
export const emailLogin = (data) => Post('/tripartite/email_login', data);

/**
 * 邮箱注册
 * @param {string} email  - 邮箱
 * @param {string} password  - 密码
 * @param {string} verificationCode - 验证码
 * @returns {Promise<object>} - 服务端返回的数据
 */
export const emailSignup = (data) => Post('/tripartite/email_register', data);

/**
 * 发送验证码
 * @param {string} email  - 邮箱
 * @returns {Promise<object>} - 服务端返回的数据
 */
export const sendCode = (data) => Post('/tripartite/send_code', data);

/**
 * 重置密码
 * @param {string} email  - 邮箱
 * @param {string} password  - 密码
 * @returns {Promise<object>} - 服务端返回的数据
 */
export const resetPassword = (data) => Post('/tripartite/reset_password', data);

/**
 * 验证验证码是否正确
 * @param {string} email  - 邮箱
 * @param {string} verificationCode - 验证码
 * @returns {Promise<object>} - 服务端返回的数据
 */
 export const checkVerificationCode = (data) => Post('/tripartite/check_verification_code', data);
