import axios from 'axios';
import pako from 'pako/dist/pako.min.js';
import { isIE } from './UaUtils';

/**
 * 判断文件格式是否为音频
 * @param {string} fileType - 文件格式
 */
export function isAudio(fileType) {
    return (
        fileType.indexOf('audio') > -1 ||
        fileType.indexOf('wav') > -1 ||
        fileType.indexOf('mp3') > -1 ||
        fileType.indexOf('m4a') > -1 ||
        fileType.indexOf('aac') > -1 ||
        fileType.indexOf('amr') > -1 ||
        fileType.indexOf('wma') > -1
    );
}

/**
 * 将服务端返回的文件字段转换为转写弹框需要的字段
 * @param {array} files - 服务端返回的文件数组
 */
export function formatFiles(files) {
    return files.map(file => {
        const { fileId, fileName: name, format: fileType, timeLen } = file;
        return {
            fileId,
            name,
            fileType,
            timeLen,
            completed: true,
            hxFileStatus: 'success',
            progress() {
                return 1;
            }
        };
    });
}

/**
 * 复制文本并保留原格式
 * @param {string} content - The content to be copied.
 * @param {function} callback - The callback function to handle the status of the copy operation.
 *
 * @return {void}
 */
export function copy(content, callback) {
    try {
        // 使用textarea保留复制格式
        const inputEle = document.createElement('textarea');
        inputEle.setAttribute('readonly', 'readonly');
        document.body.appendChild(inputEle);
        inputEle.value = content;
        inputEle.select();
        document.execCommand('Copy');
        document.body.removeChild(inputEle);
        callback && callback('success');
    } catch (error) {
        callback && callback('error');
    }
}
/**
 * 返回url中指定key的value
 */
export function getParamFromURLByKey(key) {
    var params = {};
    window.location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(
        str,
        paramKey,
        value
    ) {
        params[paramKey] = decodeURIComponent(value);
    });
    return params[key];
}

/**
 * 获取音频文件的时长
 * @param {File} file - 音频文件
 * @return {Promise} - 一个解析为音频文件时长的 Promise 对象
 */
export function getAudioDuration(file) {
    return new Promise((resolve, reject) => {
        let reader = new FileReader();
        let audioContext = new AudioContext();

        reader.onloadend = () => {
            audioContext.decodeAudioData(
                reader.result,
                buffer => {
                    resolve(parseInt(buffer.duration));
                },
                error => {
                    reject(error);
                }
            );
        };

        reader.readAsArrayBuffer(file);
    });
}

/**
 * shift 多选 获取 上下行数
 * @param row
 * @param bottom
 * @param top
 * @returns {{top: *, bottom: *}}
 */
export function getTopAndBottom(row, bottom, top) {
    let n = row.rowIndex,
        mx = bottom.rowIndex,
        mi = top.rowIndex;
    if (n > mx) {
        return {
            top: mi,
            bottom: n
        };
    } else if (n < mx && n > mi) {
        return {
            top: mi,
            bottom: n
        };
    } else if (n < mi) {
        return {
            top: n,
            bottom: mx
        };
    } else if (n == mi || n == mx) {
        return {
            top: mi,
            bottom: mx
        };
    }
}

/**
 * 获取指定名称cookie值
 * @param name
 * @returns {string}
 */
export function getCookie(name) {
    let cookie = document.cookie;
    let cookieArr = cookie.split(';');
    for (let i = 0; i < cookieArr.length; i++) {
        let arr = cookieArr[i].split('=');
        if (arr[0].replace(/^\s+|\s+$/g, '') == name) {
            return arr[1];
        }
    }
}

/**
 * 删除所有cookie
 */
export function removeAllCookie(domain) {
    var expiredTime = new Date(1970).toGMTString();
    const cookie = document.cookie;
    const cookieArr = cookie.split(';');
    const len = cookieArr.length;
    for (let i = 0; i < len; i++) {
        const temp = cookieArr[i].split('=');
        document.cookie =
            temp[0] + '=;domain=' + domain + ';path=/;expires=' + expiredTime;
    }
}

/**
 * 获取普通格式日期
 * @returns {string}
 */
export function getNowFormatDate() {
    var date = new Date();
    var seperator1 = '-';
    var seperator2 = ':';
    var month = date.getMonth();
    var strDate = date.getDate();
    if (month >= 1 && month <= 9) {
        month = '0' + month;
    }
    if (strDate >= 0 && strDate <= 9) {
        strDate = '0' + strDate;
    }
    var currentdate =
        date.getFullYear() + seperator1 + month + seperator1 + strDate;
    return currentdate;
}

/**
 * 邮箱手机号检测
 * @param rule
 * @param value
 * @param callback
 * @returns {*}
 */
export function checkContact(rule, value, callback) {
    //0{0,1}(13[0-9]|15[0-9]|153|156|18[7-9])[0-9]{8}
    let reg = /(^1\d{10}$)|(^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$)/i;
    if (!reg.test(value) && value) {
        return callback(new Error('联系方式格式错误'));
    }
    callback();
}

export function download(url, filename, failed) {
    axios({
        url: url,
        method: 'get',
        responseType: 'blob'
    })
        .then(function(res) {
            const contentDisposition = res.headers['content-disposition'];
            if (res.data && res.data.size == 0) {
                failed();
                return;
            }
            if (contentDisposition) {
                filename = window.decodeURI(
                    res.headers['content-disposition'].split('=')[1]
                );
            }
            var blob = new Blob([res.data], {
                type: res.headers['content-type']
            });
            let link = document.createElement('a');
            let evt = document.createEvent('HTMLEvents');
            evt.initEvent('click', false, false);
            link.href = window.URL.createObjectURL(blob);
            link.setAttribute('download', filename);
            link.style.display = 'none';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(link.href);
        })
        .catch(err => {
            if (failed) {
                failed(err.message);
            }
        });
}

/** await异常捕获 */
export const errCaptured = async asyncFunc => {
    try {
        const res = await asyncFunc();
        return [null, res];
    } catch (e) {
        return [e, null];
    }
};

/** 解压 */
export const unzip = string => {
    const charData = string.split('').map(x => {
        return x.charCodeAt(0);
    });
    console.log('压缩后大小', charData.length);
    const binData = new Uint8Array(charData);
    const data = pako.inflate(binData);
    const arr = new Uint16Array(data);
    let res = '';
    const chunk = 8 * 1024; //
    var i;
    for (i = 0; i < arr.length / chunk; i++) {
        res += String.fromCharCode.apply(
            null,
            arr.slice(i * chunk, (i + 1) * chunk)
        );
    }
    res += String.fromCharCode.apply(null, arr.slice(i * chunk));
    return unescape(res);
};

/** 压缩 */
export const zip = string => {
    const charData = string.split('').map(x => {
        return x.charCodeAt(0);
    });
    console.log('压缩前大小', charData.length);
    const binaryString = pako.gzip(escape(string), { to: 'string' });
    return binaryString;
};

/** 打开新窗口 */
export const openWindow = href => {
    let newWindow = window.open();
    newWindow.opener = null;
    newWindow.location = href;
    newWindow.target = '_blank';
};

export const downloadFile = res => {
    return new Promise((resolve, reject) => {
        const contentDisposition = res.headers['content-disposition'];
        if (res.data && res.data.size == 0) {
            reject();
            return;
        }
        let filename = 'unknow.zip';
        if (contentDisposition) {
            filename = window.decodeURI(
                res.headers['content-disposition'].split('=')[1]
            );
        }
        var blob = new Blob([res.data], { type: res.headers['content-type'] });
        if (isIE()) {
            window.navigator.msSaveBlob(blob, filename);
            return;
        }
        let link = document.createElement('a');
        let evt = document.createEvent('HTMLEvents');
        evt.initEvent('click', false, false);
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute('download', filename);
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(link.href);
        resolve();
    });
};

// 获取系统版本
export const getOsVersion = () => {
    const u = navigator.userAgent;
    let version = '';
    if (u.indexOf('Mac OS X') > -1) {
        // ios
        const regStr_saf = /OS [\d._]*/gi;
        const verinfo = u.match(regStr_saf);
        version =
            'IOS' +
            (verinfo + '').replace(/[^0-9|_.]/gi, '').replace(/_/gi, '.');
    } else if (u.indexOf('Android') > -1) {
        // android
        version =
            'Android' +
            u.substr(
                u.indexOf('Android') + 8,
                u.indexOf(';', u.indexOf('Android')) - u.indexOf('Android') - 8
            );
    } else if (u.indexOf('Linux') > -1) {
        // linux
        version = 'Linux';
    } else if (u.indexOf('BB10') > -1) {
        // 黑莓bb10系统
        version =
            'blackberry' +
            u.substr(
                u.indexOf('BB10') + 5,
                u.indexOf(';', u.indexOf('BB10')) - u.indexOf('BB10') - 5
            );
    } else if (u.indexOf('IEMobile') > -1) {
        // windows phone
        version =
            'winphone' +
            u.substr(
                u.indexOf('IEMobile') + 9,
                u.indexOf(';', u.indexOf('IEMobile')) -
                    u.indexOf('IEMobile') -
                    9
            );
    } else {
        const userAgent = navigator.userAgent.toLowerCase();
        if (userAgent.indexOf('windows nt 5.0') > -1) {
            version = 'Windows 2000';
        } else if (
            userAgent.indexOf('windows nt 5.1') > -1 ||
            userAgent.indexOf('windows nt 5.2') > -1
        ) {
            version = 'Windows XP';
        } else if (userAgent.indexOf('windows nt 6.0') > -1) {
            version = 'Windows Vista';
        } else if (
            userAgent.indexOf('windows nt 6.1') > -1 ||
            userAgent.indexOf('windows 7') > -1
        ) {
            version = 'Windows 7';
        } else if (
            userAgent.indexOf('windows nt 6.2') > -1 ||
            userAgent.indexOf('windows 8') > -1
        ) {
            version = 'Windows 8';
        } else if (userAgent.indexOf('windows nt 6.3') > -1) {
            version = 'Windows 8.1';
        } else if (
            userAgent.indexOf('windows nt 6.2') > -1 ||
            userAgent.indexOf('windows nt 10.0') > -1
        ) {
            version = 'Windows 10';
        } else {
            version = 'Unknown';
        }
    }
    return version;
};

export const getBrowserInfo = () => {
    const userAgent = navigator.userAgent.toLowerCase();

    // 检查userAgent以确定浏览器类型
    const isIE = userAgent.includes('msie') || userAgent.includes('trident');
    const isEdge = userAgent.includes('edg/');
    const isFirefox = userAgent.includes('firefox/');
    const isChrome = userAgent.includes('chrome/');
    const isSafari = userAgent.includes('safari/') && !isChrome;

    // 从userAgent中提取版本号
    const version =
        (isIE && userAgent.match(/(msie|rv:)\s?([\d.]+)/)[2]) ||
        (isEdge && userAgent.match(/edg\/([\d.]+)/)[1]) ||
        (isFirefox && userAgent.match(/firefox\/([\d.]+)/)[1]) ||
        (isChrome && userAgent.match(/chrome\/([\d.]+)/)[1]) ||
        (isSafari && userAgent.match(/version\/([\d.]+)/)[1]) ||
        '';

    // 根据浏览器类型返回名称
    const name =
        (isIE && 'ie') ||
        (isEdge && 'Edge') ||
        (isFirefox && 'Firefox') ||
        (isChrome && 'Chrome') ||
        (isSafari && 'Safari') ||
        '';

    // 返回包含浏览器名称和版本号的对象
    return name + version;
};

//  秒数转化为时分秒
export function formatSeconds(value) {
    //  秒
    let second = parseInt(value);
    //  分
    let minute = 0;
    //  小时
    let hour = 0;
    //  天
    //  let day = 0
    //  如果秒数大于60，将秒数转换成整数
    if (second >= 60) {
        //  获取分钟，除以60取整数，得到整数分钟
        minute = parseInt(second / 60);
        //  获取秒数，秒数取佘，得到整数秒数
        second = parseInt(second % 60);
        //  如果分钟大于60，将分钟转换成小时
        if (minute >= 60) {
            //  获取小时，获取分钟除以60，得到整数小时
            hour = parseInt(minute / 60);
            //  获取小时后取佘的分，获取分钟除以60取佘的分
            minute = parseInt(minute % 60);
            //  如果小时大于24，将小时转换成天
            //  if (hour > 23) {
            //    //  获取天数，获取小时除以24，得到整天数
            //    day = parseInt(hour / 24)
            //    //  获取天数后取余的小时，获取小时除以24取余的小时
            //    hour = parseInt(hour % 24)
            //  }
        }
    }
    let result =
        parseInt(second) < 10 ? '0' + parseInt(second) : '' + parseInt(second);
    if (minute > 0) {
        result =
            (parseInt(minute) < 10
                ? '0' + parseInt(minute)
                : '' + parseInt(minute)) +
            ':' +
            result;
    } else {
        result = '00' + ':' + result;
    }
    if (hour > 0) {
        result =
            (parseInt(hour) < 10 ? '0' + parseInt(hour) : '' + parseInt(hour)) +
            ':' +
            result;
    } else {
        result = '00' + ':' + result;
    }
    return result;
}

// 机型区分
export function getMobileOs() {
    // 获取用户代理字符串
    var userAgent = navigator.userAgent;

    // 判断是否为安卓设备
    if (userAgent.match(/Android/i) !== null) {
        return 0;
    }

    // 判断是否为iOS设备
    if (userAgent.match(/iPhone|iPad|iPod/i) !== null) {
        return 1;
    }
    // 保险代码
    return 2;
}

// 说话人随机背景色
export function generateRandomColor(i) {
    const colorList = [
        'linear-gradient(148deg, #69ABFF 0%, #3873FE 100%)',
        'linear-gradient(148deg, #A972FF 0%, #8558FF 100%)',
        'linear-gradient(148deg, #72E1D9 0%, #2DCDB6 100%)'
    ];
    return colorList[(i + 1) % 3];
}

/** 文件状态图片 */
export function statusImage({ status }) {
    const imageMap = {
        waiting: require('../assets/image/home/untransfer.png'),
        transfering: require('../assets/image/home/transfering.png'),
        complete: require('../assets/image/home/transfered.png'),
        inQueue: require('../assets/image/home/inqueue.png'),
        failed: require('../assets/image/home/transfer-failed.png')
    };
    return imageMap[status];
}
