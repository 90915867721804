<template>
    <div class="purchase-duration-card">
        <div class="time-card-wrapper">
            <div
                :class="[
                    'time-card-box',
                    index === 0 && 'no-pl',
                    index === 5 && 'no-pr'
                ]"
                v-for="(timeCard, index) in timeCardList"
                :key="timeCard.goodsId"
            >
                <time-card
                    :data="timeCard"
                    :selected="curTimeCard.goodsId === timeCard.goodsId"
                    @selected="handleSelect"
                ></time-card>
            </div>
        </div>
        <div class="price-detail-title">{{ $t('价格明细') }}</div>
        <div v-if="timeCardList.length > 0" class="settlement-wrapper">
            <div class="price-detail-wrapper">
                <price-detail :price="curTimeCard.price"></price-detail>
            </div>
            <div class="qr-code-wrapper">
                <pay-code-box
                    type="duration"
                    :goods="curTimeCard"
                    @success="handleSuccess"
                ></pay-code-box>
            </div>
        </div>
    </div>
</template>

<script>
import TimeCard from '@/components/Order/TimeCard';
import PriceDetail from '@/components/Order/PriceDetail';
import PayCodeBox from '@/components/Order/PayCodeBox';
import { fetchGoods } from '@/api/order-api';
import { mapState } from 'vuex';

export default {
    props: {
        id: {
            type: Number,
            default: -1
        }
    },
    components: {
        TimeCard,
        PriceDetail,
        PayCodeBox
    },
    data() {
        return {
            timeCardList: [],
            curTimeCard: null
        };
    },
    mounted() {
        this.getGoods();
    },
    methods: {
        /**
         * 支付成功回调
         * @param {String} type 会员/时长卡
         */
        handleSuccess(type, duration) {
            this.$emit('success', type, duration);
        },
        /**
         * 选中时长卡回调
         * @param {String} card 时长卡
         */
        handleSelect(card) {
            this.curTimeCard = card;
        },
        /**
         * 获取商品列表
         */
        async getGoods() {
            try {
                const res = await fetchGoods({ goodsType: 0 });
                this.timeCardList = res.data || [];
                if (this.id !== -1) {
                    this.curTimeCard = this.timeCardList.find(
                        card => card.goodsId === this.id
                    );
                } else {
                    this.curTimeCard = this.timeCardList[0];
                }
            } catch (error) {
                console.log('获取商品列表失败：', error);
            }
        }
    },
    computed: {
        ...mapState({
            status: state => state.status
        })
    }
};
</script>

<style scoped lang="less">
.purchase-duration-card {
    display: flex;
    flex-direction: column;
    padding: 32px;

    .time-card-wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-shrink: 0;
        width: 100%;
        overflow: hidden;

        .time-card-box {
            flex-shrink: 0;
            padding: 0 6px;
            width: calc(100% / 6);
            cursor: pointer;
        }

        .time-card-box.no-pl {
            padding-left: 0;
        }

        .time-card-box.no-pr {
            padding-right: 0;
        }
    }

    .price-detail-title {
        margin-top: 20px;
        margin-bottom: 12px;
        font-weight: 600;
        font-size: 16px;
        color: #272727;
        text-align: left;
    }

    .settlement-wrapper {
        display: flex;
        flex: 1;

        .price-detail-wrapper {
            width: 536px;
            height: 100%;
        }
        .qr-code-wrapper {
            flex: 1;
            margin-left: 20px;
            height: 100%;
        }
    }
}
</style>
