export const HISTORY_BASE =
    process.env.NODE_ENV === 'development' ||
    window.location.host.indexOf('voicecube') > -1
        ? '/'
        : window.location.host.indexOf('yytest') > -1
        ? '/voiceclub_web'
        : '/voiceclub';

export const LINKS = {
    privacy: config.privacy,
    agreement: config.agreement,
    help: config.help,
    membership: config.membership,
};

// 上传录音说明
export const UPLOAD_DESCRIPTION = [
    'Supported format: audio (wav, m4a, aac, mp3, amr, wma), video (mp4, 3GP, mkv, flv, mov, wmv, mxf, avi, m4s)',
    'The size of a single audio file should be within 1G and no more than 5 hours',
];

// 单个文件大小限制
export const UPLOAD_FILE_SIZE = 1024 * 1024 * 1024;


/** 转写状态 */
export const statusMap = {
    0: 'waiting', // 未转写
    1: 'transfering', // 正在转写
    2: 'complete', // 转写完成
    3: 'inQueue', // 转写排队中
    '-1': 'failed', // 转写失败
    '-10': 'unfind', // 未找到
};

/** 订单类型 */
export const OrderTypesMap = {
    all: null,
    evaluate: 1,
    unPay: 2,
    transfering: 3,
    closed: 4,
    complete: 5,
};

export const supportType =
    '.wav,.mp3,.m4a,.aac,.mp4,.3gp,.mkv,.flv,.mov,.wmv,.mxf,.avi,.amr,.wma,.m4s';
