<template>
    <div class="store-item">
        <div class="product-wrapper">
            <img
                class="product-bg"
                src="../../assets/image/card/card_background_blue.png"
                alt="背景"
            />
            <div class="duration-num">{{ durationShow }}</div>
            <div class="duration-unit">{{ durationNameShow }}</div>
        </div>
        <div class="product-detail">
            <div class="product-title">{{ goods.goodsName }}</div>
            <div class="product-expiry-date">
                {{ $t('有效期') }}：{{ expiryDate }}
            </div>
            <div
                :class="[
                    'product-original-price',
                    goods.discount && 'discount',
                    type === 'purchase' && 'purchase'
                ]"
            >
                <span v-if="goods.discount">{{ $t('原价') }}：</span>
                <img
                    v-else-if="type === 'redeem'"
                    class="yuelu-icon"
                    src="../../assets/image/shop/yuelu-coin.png"
                    alt="悦豆"
                />
                <span v-else>{{ goods.currency }}</span>
                <span>{{
                    type === 'redeem' ? goods.originalCoin : goods.price
                }}</span>
            </div>
            <div v-if="goods.discount" class="product-discounted-price">
                <img
                    class="yuelu-icon"
                    src="../../assets/image/shop/yuelu-coin.png"
                    alt="悦豆"
                />
                <span>{{ goods.exchangeCoin }}</span>
            </div>
            <div
                v-if="showRedeem && type === 'redeem'"
                class="redeem"
                @click="handleRedeem"
            >
                {{ $t('兑换') }}
            </div>
            <div
                v-if="type === 'purchase'"
                class="redeem"
                @click="handlePurchase"
            >
                {{ $t('购买') }}
            </div>
        </div>
        <div v-if="goods.discount" class="discount-wrapper">
            <img src="../../assets/image/shop/discount-bg.png" alt="折扣" />
            <span>{{ $t('折扣') }}</span>
        </div>
    </div>
</template>

<script>
const HOURBYSECOND = 3600;
const MINUTEBYSECOND = 60;
const DAYBYSECOND = HOURBYSECOND * 24;
const MONTHBYSECOND = DAYBYSECOND * 30;

export default {
    props: {
        type: {
            type: String,
            default: 'redeem'
        },
        goods: {
            type: Object,
            default: {
                discount: 0,
                duration: 0,
                effectiveTime: 0,
                exchangeCoin: 0,
                goodsId: 1,
                goodsIntroduce: null,
                goodsName: '半小时转写时长卡',
                goodsPicPath: null,
                originalCoin: 0
            }
        },
        showRedeem: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        handleRedeem() {
            this.$emit('redeem', this.goods);

            // 埋点
            window.weblog.report({
                id: 'voiceclub_idyun_webShop_shop_exchange',
                action: 'click'
            });
        },
        handlePurchase() {
            this.$emit('purchase', this.goods);
        }
    },
    computed: {
        durationShow() {
            const { duration } = this.goods;
            const hours = duration / HOURBYSECOND;
            if (hours >= 1) {
                return hours;
            } else {
                return duration / MINUTEBYSECOND;
            }
        },
        durationNameShow() {
            const { duration } = this.goods;
            const hours = duration / HOURBYSECOND;
            return hours >= 1 ? this.$t('小时') : this.$t('分钟');
        },
        expiryDate() {
            const { effectiveTime } = this.goods;
            const years = effectiveTime / 31536000;
            if (years >= 1) {
                return years + this.$t('年');
            } else {
                const months = effectiveTime / MONTHBYSECOND;
                if (months >= 1) {
                    return months + this.$t('个月');
                } else {
                    const days = effectiveTime / DAYBYSECOND;
                    return days + this.$t('天');
                }
            }
        }
    }
};
</script>

<style scoped lang="less">
.store-item {
    position: relative;
    display: flex;
    padding: 10px;
    background-color: #ffffff;
    border-radius: 8px;
    overflow: hidden;
    .product-wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        aspect-ratio: 96 / 110;
        flex-shrink: 0;
        width: 33.1%;
        color: #ffffff;
        .product-bg {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }
        .duration-num {
            font-weight: 600;
            font-size: 36px;
            z-index: 999;
        }
        .duration-unit {
            z-index: 999;
        }
    }
    .product-detail {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        flex: 1;
        padding: 0 12px;
        text-align: left;
        overflow: hidden;
        div {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .product-title {
            font-weight: 600;
            font-size: 16px;
            color: #243041;
        }
        .product-expiry-date,
        .product-original-price {
            color: #4f5967;
        }
        .product-original-price {
            font-weight: 600;
            font-size: 16px;
            color: #fa6400;
            .yuelu-icon {
                margin-right: 6px;
                width: 20px;
                vertical-align: bottom;
            }
        }

        .product-original-price.purchase {
            color: #000000;
        }

        .product-original-price.discount {
            font-weight: 400;
            font-size: 14px;
            color: #b9bdc3;
            text-decoration: line-through;
        }
        .product-discounted-price {
            display: flex;
            align-items: center;
            font-weight: 600;
            font-size: 16px;
            color: #fa6400;
            .yuelu-icon {
                margin-right: 6px;
                width: 20px;
                vertical-align: bottom;
            }
        }
        .redeem {
            position: absolute;
            right: 0;
            bottom: 0;
            padding: 0 16px;
            height: 32px;
            line-height: 32px;
            border-radius: 16px;
            background: linear-gradient(123deg, #ff914a 0%, #ff3d1b 100%);
            font-size: 14px;
            color: #ffffff;
            cursor: pointer;
            transition: opacity 0.1s;
        }
        .redeem:hover {
            opacity: 0.8;
        }
    }
    .discount-wrapper {
        position: absolute;
        right: 0;
        top: 0;
        aspect-ratio: 2;
        padding: 0 5px;
        min-width: 17.6%;
        font-family: 'DingTalk';
        font-size: 14px;
        color: #ffffff;
        img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }
        span {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
        }
    }
}
</style>
