import { getHelpDocument } from '../../api/helpDocument-api';
const state = {
    doucmentContent: ''
};

const getters = {};

const mutations = {
    docContent(state, data) {
        state.doucmentContent = data ? data : '';
    }
};

const actions = {
    getDocContent(context, id) {
        getHelpDocument({titleId: id}).then(res => {
            if (res.status_code === 1) {
                context.commit('docContent', res.data);
            } else {
                alert('获取帮助文档失败');
            }
        });
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
