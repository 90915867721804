<template>
  <div class="flex-col">
    404
    <div style="font-size: 30px">Page not found</div>
  </div>
</template>

<script>
export default {
  name: "not-found"
};
</script>

<style scoped>
div {
  font-size: 300px
}
</style>
