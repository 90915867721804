<template>
    <div class="signup-reset-box">
        <div class="signup-reset-title">
            {{ type === 'signup' ? 'Sign up' : 'Password Reset' }}
        </div>
        <div class="email-container">
            <el-form ref="form" :model="form" :rules="formRules">
                <el-form-item prop="email">
                    <email-input
                        v-model="form.email"
                        @input="confirmInputValid('email')"
                    ></email-input>
                </el-form-item>
                <el-form-item prop="password">
                    <password-input
                        v-model="form.password"
                        placeholder="Enter your Password"
                        @input="confirmInputValid('password')"
                    >
                    </password-input>
                </el-form-item>
                <el-form-item prop="confirmPassword">
                    <password-input
                        v-model="form.confirmPassword"
                        placeholder="Confirm your Password"
                        @input="confirmInputValid('confirmPassword')"
                    >
                    </password-input>
                </el-form-item>
                <el-form-item prop="verificationCode">
                    <div class="verify-code-wrapper">
                        <verify-code-input
                            v-model="form.verificationCode"
                            style="margin-right: 12px; width: 250px"
                            @input="confirmInputValid('verificationCode')"
                        ></verify-code-input>
                        <verify-code-button
                            :loading="verifyLoading"
                            :disabled="disableVerify"
                            style="flex: 1"
                            @click="getCode"
                            >{{ verifyText }}</verify-code-button
                        >
                    </div>
                </el-form-item>
            </el-form>
        </div>
        <submit-button
            :loading="loading"
            :disabled="disableSubmit"
            @click="type === 'signup' ? handleSignup() : handleReset()"
            >{{ type === 'signup' ? 'Sign up' : 'Reset Now' }}</submit-button
        >

        <div class="policy-wrapper">
            By using VoiceCube, you agree to the
            <a class="policy-link" :href="config.agreement" target="_blank"
                >Terms of Service</a
            >
            and
            <a class="policy-link" :href="config.privacy" target="_blank"
                >Privacy Policy</a
            >
        </div>

        <div class="login-now">
            Already have account?
            <span class="blue-text" @click="toLogin">LOG IN</span>
        </div>
    </div>
</template>

<script>
import EmailInput from '@/components/Auth/EmailInput';
import PasswordInput from '@/components/Auth/PasswordInput';
import VerifyCodeInput from '@/components/Auth/VerifyCodeInput';
import VerifyCodeButton from '@/components/Auth/VerifyCodeButton';
import SubmitButton from '@/components/Auth/SubmitButton';
import {
    emailSignup,
    sendCode,
    resetPassword,
    checkVerificationCode,
} from '@/api/login-api';
import { hashPassword } from '@/utils/passwordEncode';

export default {
    props: {
        type: {
            type: String,
            default: 'signup', // signup, forgot
        },
    },
    components: {
        EmailInput,
        PasswordInput,
        VerifyCodeInput,
        VerifyCodeButton,
        SubmitButton,
    },
    data() {
        const validateEmail = (rule, value, callback) => {
            const regex =
                /^[a-zA-Z0-9]+([._-]*[a-zA-Z0-9]+)*@([a-z0-9]+[-.])+[a-z0-9]{2,3}$/;
            if (!regex.test(value)) {
                callback(new Error('Valid email required'));
                return;
            }
            callback();
        };
        const validatePassword = (rule, value, callback) => {
            const regex = /^.{8,20}$/;
            if (!regex.test(value)) {
                callback(new Error('Must be 8-20 characters'));
                return;
            }
            if (this.form.confirmPassword !== '') {
                this.$refs.form.validateField('confirmPassword');
            }
            callback();
        };

        const validateConfirmPassword = (rule, value, callback) => {
            if (value === '' || value !== this.form.password) {
                callback(new Error(`Password doesn't match, please check`));
                return;
            }
            callback();
        };

        return {
            config, // window.config
            form: {
                email: '',
                password: '',
                confirmPassword: '',
                verificationCode: '',
            },
            formRules: {
                email: [
                    {
                        validator: validateEmail,
                        trigger: 'blur',
                    },
                ],
                password: [
                    {
                        validator: validatePassword,
                        trigger: 'blur',
                    },
                ],
                confirmPassword: [
                    {
                        validator: validateConfirmPassword,
                        trigger: 'blur',
                    },
                ],
                verificationCode: [
                    {
                        required: true,
                        message: 'Verification Code is required',
                        trigger: 'blur',
                    },
                ],
            },
            emailValid: false,
            passwordValid: false,
            confirmPasswordValid: false,
            verificationCodeValid: false,
            loading: false,
            verifyLoading: false,
            codeSent: false,
            countingDown: false,
            countDownNum: 60,
        };
    },
    methods: {
        /**
         * 获取验证码回调
         */
        async getCode() {
            try {
                this.verifyLoading = true;
                await sendCode({
                    email: this.form.email,
                });
                this.$notify.success(
                    'Verification code has been sent to your email'
                );
                this.timerCountingDown();
            } catch (error) {
                console.log(error);
                this.$notify.error('Failed to send verification code');
            } finally {
                this.verifyLoading = false;
                this.codeSent = true;
            }
        },
        /**
         * 计时开始倒计时
         */
        timerCountingDown() {
            // 在倒计时期间，需要给发送验证码按钮添加disabled属性
            // 新增变量以对此进行兜底，防止其他操作导致按钮解除禁用
            this.countingDown = true;
            const timer = setInterval(() => {
                if (this.countDownNum > 0) {
                    this.countDownNum--;
                } else {
                    clearInterval(timer);
                    this.countingDown = false;
                    this.countDownNum = 60;
                }
            }, 1000);
        },
        /**
         * 跳转登录组件
         */
        toLogin() {
            this.$emit('authChange', 'login');
        },
        /**
         * 密码状态切换
         */
        handlePassword() {
            if (this.passwordType === 'password') {
                this.passwordType = 'text';
            } else {
                this.passwordType = 'password';
            }
        },
        /**
         * 邮箱注册回调
         */
        async handleSignup() {
            this.$refs.form.validate(async (valid) => {
                if (!valid) return;
                try {
                    this.loading = true;
                    const { email, password, verificationCode } = this.form;
                    await checkVerificationCode({ email, verificationCode });
                    await emailSignup({
                        email,
                        password: hashPassword(password),
                        source: 0
                    });
                    this.$notify.success('Signup success, login now');
                    this.toLogin();
                } catch (error) {
                    console.log(error);
                } finally {
                    this.loading = false;
                }
            });
        },
        /**
         * 忘记密码回调
         */
        async handleReset() {
            this.$refs.form.validate(async (valid) => {
                if (!valid) return;
                try {
                    this.loading = true;
                    const { email, password, verificationCode } = this.form;
                    await checkVerificationCode({ email, verificationCode });
                    await resetPassword({
                        email,
                        password: hashPassword(password),
                    });
                    this.$notify.success('Password reset success, login now');
                    this.toLogin();
                } catch (error) {
                    console.log(error);
                } finally {
                    this.loading = false;
                }
            });
        },
        /**
         * 验证输入是否合法
         */
        async confirmInputValid(field) {
            this.$refs.form.validateField(field, (errorMessage) => {
                if (errorMessage) {
                    // 仅在blur时提示错误
                    this.$refs.form.clearValidate(field);
                    this[`${field}Valid`] = false;
                    return;
                }
                this[`${field}Valid`] = true;
            });
            // 这里需要补充一点：因为确认密码是根据密码来验证是否合法的
            // 当密码变动时，需要同步验证确认密码的状态
            if (field === 'password') this.confirmInputValid('confirmPassword');
        },
    },
    computed: {
        verifyText() {
            return !this.codeSent
                ? 'Get Code'
                : this.countingDown
                ? `${this.countDownNum}s`
                : 'Resend';
        },
        disableSubmit() {
            return (
                !this.emailValid ||
                !this.passwordValid ||
                !this.confirmPasswordValid ||
                !this.verificationCodeValid ||
                !this.codeSent
            );
        },
        disableVerify() {
            return (
                !this.emailValid ||
                !this.passwordValid ||
                !this.confirmPasswordValid ||
                this.countingDown
            );
        },
    },
};
</script>

<style scoped lang="less">
.signup-reset-box {
    position: relative;
    height: 100%;
    text-align: center;

    .signup-reset-title {
        font-weight: 600;
        font-size: 28px;
        color: #1d2129;
    }

    .email-container {
        margin-top: 82px;
    }

    .verify-code-wrapper {
        display: flex;
    }

    .login-now {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        font-weight: 500;
        font-size: 16px;
        color: #000000;

        .blue-text {
            color: #1876ff;
            cursor: pointer;
        }
    }

    .policy-wrapper {
        margin-top: 16px;
        font-weight: 500;
        font-size: 13px;
        color: rgba(0, 0, 0, 0.3);
        word-break: normal;
        .policy-link {
            color: #000000;
            text-decoration: none;
        }
    }
}
</style>
