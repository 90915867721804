import Vue from 'vue';
import RewardBox from '@/components/Reward/RewardBox';
import store from '@/store';
import router from '@/router';

const RewardBoxCtr = Vue.extend(RewardBox);

export default function createRewardBox(wrapper, reward) {
    const instance = new RewardBoxCtr({
        el: document.createElement('div'),
        propsData: { reward },
        store,
        router
    });
    wrapper.appendChild(instance.$el);
    return instance;
}
