<template>
    <el-dialog
        :class="[
            'cashier-dialog',
            activeTab === 0 && 'vip',
            showTab && 'showTab'
        ]"
        :visible.sync="cashierVis"
        v-if="cashierVis"
        :close-on-click-modal="false"
        :title="title"
        :before-close="beforeClose"
    >
        <div v-if="showTab" class="purchase-tabs">
            <div
                :class="[
                    'purchase-vip',
                    'purchase-tab',
                    activeTab === 0 && 'active'
                ]"
                @click="activeTab = 0"
            >
                <img
                    class="purchase-tab-img"
                    src="../../assets/image/shop/purchase-tab-bg.png"
                    alt="购买会员"
                />
                <span>购买会员</span>
            </div>
            <div
                :class="[
                    'purchase-duration',
                    'purchase-tab',
                    activeTab === 1 && 'active',
                    'reverse'
                ]"
                @click="activeTab = 1"
            >
                <img
                    class="purchase-tab-img"
                    src="../../assets/image/shop/purchase-tab-bg.png"
                    alt="购买时长卡"
                />
                <span>购买时长卡</span>
            </div>
        </div>

        <purchase-vip
            v-if="activeTab === 0"
            @success="handleSuccess"
            :style="{ height: showTab ? 'calc(100% - 44px)' : '100%' }"
        ></purchase-vip>

        <purchase-duration-card
            v-else
            :id="id"
            @success="handleSuccess"
            :style="{ height: showTab ? 'calc(100% - 44px)' : '100%' }"
        ></purchase-duration-card>
    </el-dialog>
</template>

<script>
import PurchaseVip from '@/components/Order/PurchaseVip';
import PurchaseDurationCard from '@/components/Order/PurchaseDurationCard';

export default {
    props: {
        title: {
            type: String,
            default: ''
        },
        showTab: {
            type: Boolean,
            default: false
        },
        defaultTab: {
            type: Number,
            default: 0
        },
        cashierVis: {
            type: Boolean,
            default: false
        },
        id: {
            type: Number,
            default: -1
        }
    },
    components: {
        PurchaseVip,
        PurchaseDurationCard
    },
    data() {
        return {
            activeTab: 0
        };
    },
    methods: {
        /**
         * 关闭dialog之前的回调
         */
        beforeClose() {
            this.$emit('beforeClose');
        },
        /**
         * 订单支付成功后回调
         * @param {String} type 订单类型
         * @param {Number} duration 购买时长
         */
        handleSuccess(type, duration) {
            this.$emit('close', type, duration);
        }
    },
    watch: {
        defaultTab: {
            handler() {
                this.activeTab = this.defaultTab;
            },
            immediate: true
        }
    }
};
</script>

<style scoped lang="less">
.cashier-dialog /deep/ .el-dialog {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 auto !important;
    width: 960px;
    height: 538px;
    background-color: #ffffff;
    border-radius: 16px;
    overflow: hidden;

    .el-dialog__header {
        position: absolute;
        top: 0;
        width: 100%;
        padding-top: 16px;
        height: 70px;
        line-height: 44px;
        background-image: url(../../assets/image/shop/purchase-card-bg.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;

        .el-dialog__title {
            font-weight: 600;
            font-size: 16px;
            color: #ffffff;
        }

        .el-dialog__headerbtn .el-dialog__close {
            font-size: 24px;
        }
    }
    .el-dialog__body {
        position: absolute;
        top: 70px;
        padding: 0;
        width: 100%;
        height: calc(100% - 70px);
        background-color: inherit;

        .purchase-tabs {
            display: flex;
            height: 44px;
            background: linear-gradient(131deg, #acdeff 0%, #d6f1ff 100%);

            .purchase-tab {
                position: relative;
                width: 50%;
                height: 100%;
                line-height: 44px;
                font-size: 20px;
                font-weight: 600;
                color: #272727;
                background-color: transparent;
                cursor: pointer;

                .purchase-tab-img {
                    visibility: hidden;
                }

                span {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }
            }

            .purchase-tab.active {
                color: #272727;

                .purchase-tab-img {
                    visibility: visible;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    height: 59px;
                }
            }

            .purchase-tab.active.reverse .purchase-tab-img {
                transform: rotateY(180deg);
                // 反转后会上移1px
                bottom: -1px;
                height: 59px;
            }
        }
    }
}

.cashier-dialog.showTab /deep/ .el-dialog {
    height: 582px;
}

.cashier-dialog.vip /deep/ .el-dialog {
    .el-dialog__header {
        background-image: url(../../assets/image/shop/purchase-vip-bg.png);
    }

    .purchase-tabs {
        background: linear-gradient(131deg, #ffdeac 0%, #feeec4 100%);

        .purchase-tab {
            color: #804e13;
        }
    }
}
</style>
