import { Get, Post } from '@/utils/HttpUtils';

/* 
    获取消息列表
    params: {
        page: 页码,
        size: 每页大小
    }
*/
// todo 以后大数据量要进行分页
export const fetchMessage = data => Post('notify/get_list', data);

/* 
    获取未读消息数量
    params: {
    }
*/
export const fetchUnreadSize = () => Post('notify/get_unread_size');

/* 
    已读
    params: {
        notifyId: 消息id
    }
*/
export const readMsg = data => Post('notify/read_msg', data);

/* 
    全部已读
    params: {
    }
*/
export const readAllMsg = () => Post('notify/read_all_msg');
