import { ONEKB } from '@/constants/audio';

const filters = {
    /**
     * 格式化价格，小数点后两位为00则去掉小数点
     * @param {String} price 价格
     * @returns {String} 格式化后价格
     */
    formatPrice(price) {
        return price.split('.')[1] === '00' ? price.split('.')[0] : price;
    },

    /* 消息发送时间格式化 今天显示时间 昨天显示昨天 以前的日期显示日期：今年不显示年，往年显示年 */
    messageDateFormate(timestamp, lang = 'zh') {
        const now = new Date();
        const yesterday = new Date(now);
        yesterday.setDate(now.getDate() - 1);

        const date = new Date(timestamp * 1000); // 将秒级别的时间戳转换为毫秒级别的时间戳

        const isToday =
            date.getDate() === now.getDate() &&
            date.getMonth() === now.getMonth() &&
            date.getFullYear() === now.getFullYear();
        const isYesterday =
            date.getDate() === yesterday.getDate() &&
            date.getMonth() === yesterday.getMonth() &&
            date.getFullYear() === yesterday.getFullYear();
        const isEarlierThisYear = date.getFullYear() === now.getFullYear();

        if (isToday) {
            // 仅显示时分
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            return hours + ':' + minutes;
        } else if (isYesterday) {
            // 显示昨天
            if (lang === 'zh') {
                return '昨天';
            } else {
                return 'Yesterday';
            }
        } else if (isEarlierThisYear) {
            // 显示月和日
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            return month + '-' + day;
        } else {
            // 显示年月日
            const year = date.getFullYear().toString();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            return year + '-' + month + '-' + day;
        }
    },
    /** 毫秒转换时分秒 */
    toHHmmss: function (time, type) {
        if (type === 's') {
            time = time * 1000;
        }
        let hours = parseInt(time / (3600 * 1000), 10);
        hours = hours < 10 ? `0${hours}` : hours;
        let minutes = parseInt((time % (1000 * 60 * 60)) / (1000 * 60), 10);
        minutes = minutes < 10 ? `0${minutes}` : minutes;
        let seconds = parseInt((time % (1000 * 60)) / 1000, 10);
        seconds = seconds < 10 ? `0${seconds}` : seconds;
        // 小时为00则不显示
        const s = `${hours == '00' ? '' : `${hours}:`}${minutes}:${seconds}`;
        return s;
    },

    dateFormate: (data, type) => {
        if (!data) return '';
        let date = new Date(type === 's' ? data * 1000 : data);
        let format = 'hh:mm:ss dd/MM/yyyy';
        let o = {
            'M+': date.getMonth() + 1,
            'd+': date.getDate(),
            'h+': date.getHours(),
            'm+': date.getMinutes(),
            's+': date.getSeconds(),
            'q+': Math.floor((date.getMonth() + 3) / 3),
            S: date.getMilliseconds(),
        };
        if (/(y+)/.test(format))
            format = format.replace(
                RegExp.$1,
                (date.getFullYear() + '').substr(4 - RegExp.$1.length)
            );
        for (var k in o) {
            if (new RegExp('(' + k + ')').test(format))
                format = format.replace(
                    RegExp.$1,
                    RegExp.$1.length == 1
                        ? o[k]
                        : ('00' + o[k]).substr(('' + o[k]).length)
                );
        }
        return format;
    },

    formatSize: function (byte) {
        if (byte < 1024) {
            // return parseInt(byte, 10) + 'B';
            return Number(byte).toFixed(1) + 'B';
        } else if (byte < Math.pow(1024, 2)) {
            // return parseInt(byte / Math.pow(1024, 1), 10) + 'KB';
            return Number(byte / Math.pow(1024, 1)).toFixed(1) + 'KB';
        } else if (byte < Math.pow(1024, 3)) {
            // return parseInt(byte / Math.pow(1024, 2), 10) + 'MB';
            return Number(byte / Math.pow(1024, 2)).toFixed(1) + 'MB';
        } else if (byte >= Math.pow(1024, 3)) {
            // return parseInt(byte / Math.pow(1024, 3), 10) + 'GB';
            return Number(byte / Math.pow(1024, 3)).toFixed(1) + 'GB';
        }
    },

    handleTotalCount: function (val, max) {
        return val > max ? `${max}+` : val || 0;
    },

    roughlyTime: function (second) {
        if (second < 60) {
            return second + '秒';
        } else if (second < 3600) {
            return parseInt(second / 60) + '分钟';
        } else {
            return parseInt(second / 3660) + '小时';
        }
    },

    // 秒转换成yyyy/MM/dd hh:mm的格式
    secondsToFormattedDate(seconds) {
        // 创建一个 Date 对象，将秒数转换为毫秒数
        const date = new Date(seconds * 1000);

        // 使用 Date 对象的方法获取年、月、日
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 月份从0开始，需要加1；使用 padStart 方法补零
        const day = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');

        const currentYear = new Date().getFullYear();
        // 假如是今年则不显示
        const formatYear = year === currentYear ? '' : '/' + year;
        // 拼接为格式化的日期字符串
        const formattedDate = `${day}/${month}${formatYear} ${hours}:${minutes}`;

        return formattedDate;
    },

    // 秒转换成x时x分x秒格式
    secondsToHHmmss(seconds) {
        const hours = Math.floor(seconds / 3600); // 计算小时数
        const minutes = Math.floor((seconds % 3600) / 60); // 计算分钟数
        const remainingSeconds = seconds % 60; // 计算剩余的秒数

        let formattedTime = '';

        // 如果小时数大于0，则显示小时部分
        if (hours > 0) {
            formattedTime += `${hours}${hours === 1 ? 'hour,' : 'hours,'}`;
        }

        // 如果分钟数大于0，则显示分钟部分
        if (minutes > 0) {
            formattedTime += `${minutes}${
                minutes === 1 ? 'minute' : 'minutes'
            }${hours > 0 ? ',' : ' and '}`;
        }

        // 显示剩余的秒数部分
        formattedTime += `${remainingSeconds}${
            remainingSeconds === 1 ? 'second' : 'seconds'
        }`;

        return formattedTime;
    },

    // 秒转换成HHmm
    secondsToHoursAndMinutes(seconds) {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        return `${hours} h ${minutes > 0 ? minutes + ' m ' : ''}`;
    },

    // 秒转换成yyyy-MM-dd hh:mm格式 假如是今天不显示年月日，改为 今天hh:mm；
    // 假如是今年不显示年份，只显示MM-dd hh:mm
    formatDateTime(seconds) {
        const date = new Date(seconds * 1000); // 将秒数转换为毫秒数
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2); // 月份从0开始计数，所以需要加1
        const day = ('0' + date.getDate()).slice(-2);
        const hours = ('0' + date.getHours()).slice(-2);
        const minutes = ('0' + date.getMinutes()).slice(-2);

        const now = new Date(); // 当前时间
        const today = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate()
        ); // 今天的日期

        const formattedDate =
            year == now.getFullYear() && date >= today
                ? ''
                : year != now.getFullYear()
                ? year + '-' + month + '-' + day
                : month + '-' + day;
        const formattedTime =
            year == now.getFullYear() && date >= today
                ? 'Today ' + hours + ':' + minutes
                : hours + ':' + minutes;

        return formattedDate + ' ' + formattedTime;
    },
    /**
     * 文件大小转换
     * @param {number} size - 文件大小（字节）
     * @returns {string} 文件大小（MB）
     */
    fileSizeFormat(size) {
        if (size < ONEKB) {
            return size + 'B';
        } else if (ONEKB <= size && size < ONEKB * ONEKB) {
            return (size / ONEKB).toFixed(1) + 'KB';
        } else {
            return (size / ONEKB / ONEKB).toFixed(1) + 'MB';
        }
    },
    /**
     * 秒转换为yyyy-MM-dd格式
     * @param {number} - 秒数
     * @returns {string} - 转换后的日期字符串
     */
    secondsToyMd(seconds) {
        var date = new Date(seconds * 1000);
        var year = date.getFullYear();
        var month = ('0' + (date.getMonth() + 1)).slice(-2);
        var day = ('0' + date.getDate()).slice(-2);

        return day + '/' + month + '/' + year;
    },
};
export default filters;
