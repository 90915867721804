const state = {
    fileList: [],
};

const mutations = {
    setFileList(state, fileList) {
        state.fileList = fileList;
    },
};

export default {
    namespace: true,
    state,
    mutations,
};
