import Vue from 'vue';
import Vuex from 'vuex';
import voiceDetail from './modules/voiceDetail';
import homepage from './modules/homepage';
import myaudio from './modules/myaudio';
import helpDocument from './modules/helpDocument';
import feedback from './modules/feedback';
import voiceClone from './modules/voiceClone';
import upload from './modules/upload';
import menu from './modules/menu';
import { checkUser, getUserInfo } from '../api/user-api';
import { fetchUserAsset, fetchActivity } from '@/api/signIn-api';
import { removeAllCookie } from '@/utils/CommonUtils';

Vue.use(Vuex);
const state = {
    userId: 0,
    userName: '',
    status: false,
    userInfo: {
        userPhoto: '',
        nickName: '',
        isMember: false,
        memberEffectiveTime: 0,
    },

    /* 签到&商城相关 */
    allTimeCard: 0,
    allCoinNumber: 0,
    redeemVis: false,
    activityList: [],
    // 是否显示登录弹框
    loginShow: false,
};

const getters = {};

const mutations = {
    getActivity(state, data) {
        state.activityList = data;
    },
    setRedeemVis(state, data) {
        state.redeemVis = data;
    },
    getUserAsset(state, data) {
        const { allCoinNumber, allTimeCard } = data.data;
        state.allCoinNumber = allCoinNumber;
        state.allTimeCard = allTimeCard;
    },
    setUserInfo(state, data) {
        state.userInfo = { ...state.userInfo, ...data };
    },
    // 用户登录
    login(state, data) {
        state.userId = data.userId;
        state.status = data.status;
    },
    // 用户登出
    logout(state) {
        state.status = false;
        state.userId = 0;
        state.userName = '';
        this.commit('resetUserInfo');
    },
    setMenuList(state, data) {
        state.menuList = data;
    },
    /**
     * 重置用户信息
     */
    resetUserInfo(state) {
        const data = {
            userPhoto: '',
            nickName: '',
            isMember: false,
            memberEffectiveTime: 0,
        };
        state.userInfo = data;
    },
    /**
     * 打开登录弹框
     */
    loginShow(state, data) {
        state.loginShow = data;
    },
};
const actions = {
    async getActivity() {
        try {
            const res = await fetchActivity();
            this.commit('getActivity', res.data);
        } catch (error) {
            console.log(error);
        }
    },
    async getUserAsset() {
        try {
            const res = await fetchUserAsset();
            this.commit('getUserAsset', res);
        } catch (error) {
            console.log(error);
        }
    },

    // 用户登出
    logout() {
        // 清除localstorage
        localStorage.removeItem('userId');
        localStorage.removeItem('token');
        removeAllCookie(location.hostname);
        this.commit('logout');
    },

    // 初始化数据
    async initStatus(state, callback) {
        const userId = localStorage.getItem('userId');
        // 用户已登录
        if (userId) {
            const params = {
                status: true,
                userId,
            };
            // vuex的赋值提前 目的是在账户相关接口相应前 保证子页面mounted中需要判断status的方法可直接调用 防止出现异常
            state.commit('login', params);
            // cookie添加userid，以支持埋点平台获取userid
            document.cookie = `userid=${userId}`;
            checkUser().then((res) => {
                if (!res.data) {
                    this.commit('logout');
                }
            });
            // await阻拦login进行vuex赋值操作，进而阻拦子组件判断用户登录后的逻辑执行
            const res = await callback();
            // 如果确定继续注销则不执行后续vuex操作
            if (res === true) {
                state.dispatch('logout');
            } else if (res === false) {
                // 需要手动触发getUserInfo方法以通知服务端用户放弃了注销并重载页面以fetch服务端数据
                getUserInfo().then(() => {
                    window.location.reload();
                });
            }
            return;
        }
        // 用户未登录
        const params = {
            status: false,
        };
        state.commit('login', params);
        state.dispatch('homepage/getStoreInfo');
    },
};

const store = new Vuex.Store({
    state,
    getters,
    mutations,
    actions,
    modules: {
        voiceDetail,
        homepage,
        myaudio,
        helpDocument,
        feedback,
        voiceClone,
        upload,
        menu,
    },
});

export default store;
