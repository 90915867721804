import CryptoJS from 'crypto-js';

/**
 * 密码加密
 * @param {string} password 密码
 * @param {string} salt 盐值
 */
export function hashPassword(password, salt = 'yuelu') {
    const passwordWithSalt = password + salt;

    // 使用 CryptoJS 对密码和盐值进行第一次哈希
    // let firstHash = CryptoJS.SHA256(passwordWithSalt);

    // 在第一次哈希的结果上再进行一次哈希
    let secondHash = CryptoJS.MD5(passwordWithSalt);

    return secondHash.toString();
}
